import React from 'react';
import HeaderBar from './../../../Components/HeaderBar/HeaderBar';
import { Container, Card, Icon, Image, Table, Header, Progress, Divider, Grid, Button, Transition} from 'semantic-ui-react';
import DonutChart from './Components/DonutChart';
import BarChart from './Components/BarChart';
import StackedBarChart from './Components/StackedBarChart';
import LineChart from './Components/LineChart';
import BubbleChart from './Components/BubbleChart';
// import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default class Coin_Flipper extends React.Component {

   constructor(props){
      super(props)

      this.state = {
         last_flip: null,
         percent: 0,
         num_heads:0,
         num_tails:0,
         reflip_coin: true
      }

      this.flipCoin = this.flipCoin.bind(this);
      this.clear = this.clear.bind(this);
   }

   flipCoin(){
      let { num_heads, num_tails } = this.state;
      let value = Math.random();


      if(value < .5){
         let newNumHeads = num_heads + 1;
         let newPercent = (newNumHeads + num_tails > 0) ? Math.round((newNumHeads * 100) / (newNumHeads + num_tails), 2) : 0;
         this.setState({num_heads: newNumHeads, percent: newPercent, last_flip: 'Heads'})
      } else {
         let newNumTails = num_tails + 1;
         let newPercent = (num_heads + newNumTails > 0) ? Math.round((num_heads* 100) / (num_heads + newNumTails), 2) : 0;
         this.setState({num_tails: newNumTails, percent: newPercent, last_flip: 'Tails'})
          // console.log(num_heads, num_tails )
      }

      this.setState((prevState) => ({ reflip_coin: !prevState.reflip_coin }))


   }

   clear(){
      let { num_heads, num_tails } = this.state;
      this.setState({num_heads: 0, num_tails:0, percent:0, last_flip: null})
      this.setState((prevState) => ({ reflip_coin: !prevState.reflip_coin }))
   }


   render(){

      let {last_flip, percent, num_heads, num_tails, reflip_coin} = this.state;
      let color = (!!last_flip) ? ( (last_flip === 'Heads') ? 'orange' : 'purple') : 'black';

      const heads_image = '/Images/heads_bronze.png';
      // const tails_image1 = '/Images/tails_coin.jpg';
      const tails_image = '/Images/tails_bronze.png';

      const heads_border = (num_heads > num_tails) ? 'orange' : 'black';
      const tails_border = (num_tails > num_heads) ? 'purple' : 'black';
      const heads_text = (num_heads > num_tails) ? 'orange' : 'white';
      const tails_text = (num_tails > num_heads) ? 'purple' : 'white';



      return (
         
            <Container fluid >
            <Card fluid raised >
            <Grid fluid stackable style={{backgroundColor:'black'}}>
               

               <Grid.Row fluid columns={2} stretched style={{ marginTop: 50}}>
                  
                  <Grid.Column fluid>

                     <Grid.Row >
                           <Grid.Column stretched centered style={{borderColor: heads_border, minWidth: 100,  padding: 5 }}>
                              <Header style={{color:heads_text, fontSize:'2em'}}>{ num_heads + ' heads' }</Header>
                           </Grid.Column>
                           <Grid.Column stretched centered style={{borderColor: tails_border, minWidth: 100,  padding: 5 }}>
                              <Header style={{color:tails_text, fontSize:'2em'}}>{ num_tails + ' tails'  }</Header>
                           </Grid.Column>
                     </Grid.Row>


         
                     <Grid.Row stretched centered >

                        
                        

                        <Grid.Column centered style={{fontColor: 'white', fontSize:'1.75em', marginTop: 50}}>
                           <BarChart  num_heads={num_heads} num_tails={num_tails} width={400} height={500}/>
                        </Grid.Column>




                       {/* <Grid.Column centered style={{fontColor: 'white', fontSize:'1.75em', backgroundColor: 'black'}}>
                           <DonutChart team_color={'teal'} percent={percent} points_flag= {false} title='(%) Heads Flipped' width={400} height={300}/>
                        </Grid.Column>*/}
                        
                        
                      </Grid.Row>
                  </Grid.Column>

                  <Grid.Column fluid>

                      <Grid.Row >

                           <Grid.Column>

                           </Grid.Column>

                           <Grid.Column>

                           { ((num_heads !=0) || (num_tails !=0)) &&
                           <div  style={{color:'white', minWidth: 100, minHeight: 40, padding: 10, marginRight: 20 }}>
                              {!!last_flip &&
                              <div>
                              <span style={{fontSize: 44, color:'white'}}>{ 'You have flipped '}</span>
                              
                                 <span style={{fontSize: 44, color:color,}}>{ last_flip }</span>
                              
                              </div>
                              }
                           </div>
                           }
                           { ((num_heads ==0) && (num_tails ==0)) &&
                           <div  style={{color:'white', minWidth: 100, minHeight: 40, padding: 10, marginRight: 20 }}>
                              
                              <div>
                                 <span style={{fontSize: 44, color:'white'}}>{ 'You have no flips'}</span>
                              </div>
                         
                           </div>
                           }
                        </Grid.Column>
                  
                      </Grid.Row>

                     
                      <Grid.Row stretched centered  >
                        <Button size={'big'} color={'teal'} onClick={this.flipCoin} > Flip </Button>
                        <Button style={{color: 'teal'}} size={'big'} onClick={this.clear} >Clear</Button>
                     </Grid.Row>
                      


                     {(last_flip == 'Heads') && (reflip_coin == true) &&
                     <Grid.Row stretched centered  >
                        <Transition transitionOnMount={true}  animation='vertical flip' duration={500}>
                           <Image color= 'white' centered size='medium' src={heads_image} />
                        </Transition>
                     </Grid.Row>
                     }

                     {(last_flip == 'Heads') && (reflip_coin == false) &&
                     <Grid.Row stretched centered  >
                        <Transition transitionOnMount={true}  animation='vertical flip' duration={500}>
                           <Image color= 'white' centered size='medium' src={heads_image} />
                        </Transition>
                     </Grid.Row>
                     }

                     {(last_flip == 'Tails') && (reflip_coin == true) &&
                     <Grid.Row stretched centered  >
                        <Transition transitionOnMount={true}  animation='vertical flip' duration={500}>
                           <Image color= 'white' centered size='medium' src={tails_image} />
                        </Transition>
                     </Grid.Row>
                     }

                     {(last_flip == 'Tails') && (reflip_coin == false) &&
                     <Grid.Row stretched centered  >
                        <Transition transitionOnMount={true}  animation='vertical flip' duration={500}>
                           <Image color= 'white' centered size='medium' src={tails_image} />
                        </Transition>
                     </Grid.Row>
                     }

                     {(num_heads == 0) && (num_tails == 0) &&
                     <Grid.Row stretched centered  >
                        <Image color= 'white' centered size='medium' src={heads_image} />
                     </Grid.Row>
                     }


                  </Grid.Column>

            
               
                  
               </Grid.Row>

               <Grid.Row  style={{backgroundColor:'black', minHeight:'10em'}}>
                <Container style={{backgroundColor:'black'}}/>
               </Grid.Row >

              

            </Grid>
            </Card>
            </Container>
   
        
      )
   }

}