import React from 'react';
import HeaderBar from './../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed, Form, Input, Button, TextArea, Message} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";

export default class Contact extends React.Component {

	constructor(props){
		super(props);

		this.state={
         
     	 firstName: '',
     	 lastName: '',
     	 email: '',
     	 subject: '',
     	 messageContent: '',
    
         validEmailSubmission: false,
         validEmailSubmissionCount: 0,
         invalidEmailWarning: false,
         notAllFieldsCompletedWarning: false,
	    }

		this.updateFirstName = this.updateFirstName.bind(this);
		this.updateLastName = this.updateLastName.bind(this);
		this.updateEmailValue = this.updateEmailValue.bind(this);
		this.updateSubject = this.updateSubject.bind(this);
		this.updateMessageContent = this.updateMessageContent.bind(this);
		this.submitMessage = this.submitMessage.bind(this);


	}

	updateFirstName(event, {value}){
		this.setState({firstName:value})
	}

	updateLastName(event, {value}){
		this.setState({lastName:value})
		
	}

	updateEmailValue(event, {value}){
		this.setState({email:value})
		
	}

	updateSubject(event, {value}){
		this.setState({subject:value})
		
	}

	updateMessageContent(event, {value}){
		this.setState({messageContent:value})
		
		
	}

	async submitMessage(event, {value}){

		 let { validEmailSubmissionCount, firstName, lastName, email, subject, messageContent} = this.state;

	

		 


	
		if(firstName.length > 0 && lastName.length > 0 && email.length > 0 && subject.length > 0 && messageContent.length > 0){
	    
		    if(email.length < 50 && email.includes("@") &&  email.includes(".")){

		    	
		    	let data = {firstName: firstName, lastName:lastName, email: email,
		    	subject: subject, messageContent: messageContent};
		    	// console.log(waitlistEmail);
		    	const response = await fetch(`/api/contact_me_message`, {
			        method: 'POST',
			        headers: {'Content-Type': 'application/json'},
			        body: JSON.stringify(data)
			      })
			    const success_response = await response.json();

			    // this.updateEmailValue(null, {'value': ''});
			    validEmailSubmissionCount += 1
			    this.setState({validEmailSubmission: true, invalidEmailWarning:false, notAllFieldsCompletedWarning: false, validEmailSubmissionCount: validEmailSubmissionCount, firstName: '', lastName: '', email: '', subject: '', messageContent:''});

		    } else {

		    	this.setState({validEmailSubmission: false, invalidEmailWarning: true, notAllFieldsCompletedWarning: false, validEmailSubmissionCount: validEmailSubmissionCount})

		    }

		} else {

		    	this.setState({validEmailSubmission: false, invalidEmailWarning: false, notAllFieldsCompletedWarning: true, validEmailSubmissionCount: validEmailSubmissionCount})

		}

		
		
		
	}


	render(){

		const mePic = '/Images/me/me.png';
		let { invalidEmailWarning, notAllFieldsCompletedWarning, validEmailSubmission, validEmailSubmissionCount } = this.state;

		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>		


					<Grid.Row stackable columns={2} centered width={'36em'}>

					<Grid.Column>
						<Card raised fluid style={{padding: '5em'}}>

						<Grid rows={3} stackable>

						<Grid.Row rows={3} stackable centered>

							<Image fluid src={mePic} size='small' style={{height: '30em', width:'25em'}}/>
						</Grid.Row>

						<Grid.Row rows={3} stackable>
							<Container style={{textAlign: 'center', padding:'1em'}}>
								<Header as='h2' style={{fontColor:'gray', textAlign: 'center'}}>
								Contact James
								</Header>
							</Container>
							<Container  style={{fontColor:'black'}}>
							Keep your message, short, simple, and direct and I'll get back to you as soon as I can!
							</Container>
					  	</Grid.Row>


					  </Grid>
					  </Card>
			
					</Grid.Column>

					<Grid.Column>
						<Card raised fluid style={{padding: '5em'}}>

						<Grid rows={1} stackable>

						

						<Grid.Row rows={3} stackable>
							{ ((validEmailSubmissionCount % 2) == 1) &&
							<Form>
						    <Form.Group widths='equal'>
						      
						      <Form.Field
						        id='form-input-control-first-name'
						        control={Input}
						        label='First name'
						        maxLength={20}
						        placeholder='First name'
						        onChange={(event, data)=>{this.updateFirstName(event, data)}}
						      />
						      <Form.Field
						        id='form-input-control-last-name'
						        control={Input}
						        label='Last name'
						        maxLength={20}
						        placeholder='Last name'
						        onChange={(event, data)=>{this.updateLastName(event, data)}}
						      />
						     
						    </Form.Group>
						    <Form.Field
						      id='form-input-control-email'
						      control={Input}
						      label='Email'
						      maxLength={50}
						      placeholder='Enter email here'
						      onChange={(event, data)=>{this.updateEmailValue(event, data)}}
						      
						    />
						    <Form.Field
						        id='form-input-control-subject'
						        control={Input}
						        label='Subject'
						        maxLength={20}
						        placeholder='Subject'
						        onChange={(event, data)=>{this.updateSubject(event, data)}}
						      />
						    <Form.Field
						      id='form-textarea-control-opinion'
						      control={TextArea}
						      label='Message (limit 500 characters)'
						      maxLength={500}
						      placeholder='Message'
						      rows='5'
						      onChange={(event, data)=>{this.updateMessageContent(event, data)}}
						    />
			
						    <Form.Field
						      id='form-button-control-public'
						      control={Button}
						      content='Send'
						      color='teal'
						      onClick={(event, data)=>{this.submitMessage(event, data)}}
						    />
					
							
					  		</Form>
					  		}

					  		{(validEmailSubmissionCount == 0 || ((validEmailSubmissionCount % 2) == 0)) &&
					  		<Form>
						    <Form.Group widths='equal'>
						      
						      <Form.Field
						        id='form-input-control-first-name'
						        control={Input}
						        label='First name'
						        maxLength={20}
						        placeholder='First name'
						        onChange={(event, data)=>{this.updateFirstName(event, data)}}
						      />
						      <Form.Field
						        id='form-input-control-last-name'
						        control={Input}
						        label='Last name'
						        maxLength={20}
						        placeholder='Last name'
						        onChange={(event, data)=>{this.updateLastName(event, data)}}
						      />
						     
						    </Form.Group>
						    <Form.Field
						      id='form-input-control-email'
						      control={Input}
						      label='Email'
						      maxLength={50}
						      placeholder='Enter email here'
						      onChange={(event, data)=>{this.updateEmailValue(event, data)}}
						      
						    />
						    <Form.Field
						        id='form-input-control-subject'
						        control={Input}
						        label='Subject'
						        maxLength={20}
						        placeholder='Subject'
						        onChange={(event, data)=>{this.updateSubject(event, data)}}
						      />
						    <Form.Field
						      id='form-textarea-control-opinion'
						      control={TextArea}
						      label='Message (limit 500 characters)'
						      maxLength={500}
						      placeholder='Message'
						      rows='5'
						      onChange={(event, data)=>{this.updateMessageContent(event, data)}}
						    />
							
			
						    <Form.Field
						      id='form-button-control-public'
						      control={Button}
						      content='Send'
						      color='teal'
						      onClick={(event, data)=>{this.submitMessage(event, data)}}
						    />
					
							
					  		</Form>
					  		}
					  	</Grid.Row>

						{ (notAllFieldsCompletedWarning) &&
						<Grid.Row stackable columns={2} centered width={'36em'}>

				            		<Container centered fluid style={{width:'30em'}}>
				            		<Message warning>
									    <Message.Header>Please complete all fields</Message.Header>
									    <p>You must complete all fields to send a message</p>
									  </Message>
									</Container>
									
						</Grid.Row>
						}

						{ (invalidEmailWarning) &&
							<Grid.Row stackable columns={2} centered width={'36em'}>

					            		<Container centered fluid style={{width:'30em'}}>
					            		<Message negative>
										    <Message.Header>Invalid email</Message.Header>
										    <p>Please enter in a valid email address to contact James</p>
										  </Message>
										</Container>
										
							</Grid.Row>
						}


						{ (validEmailSubmission) &&
						<Grid.Row stackable columns={2} centered width={'36em'}>

				            		<Container centered fluid style={{width:'30em'}}>
				            		<Message positive>
									    <Message.Header> Message sent!</Message.Header>
									    <p> Thanks for your message! </p>
									  </Message>
									</Container>
									
						</Grid.Row>
						}

						

	               		{ (!notAllFieldsCompletedWarning &&!invalidEmailWarning && !validEmailSubmission) &&
						<Grid.Row  style={{backgroundColor:'white', minHeight:'7.1em'}}>
	                		<Container style={{backgroundColor:'white'}}/>
	               		</Grid.Row >
	               		}




					  </Grid>
					  </Card>
			
					</Grid.Column>
						



					</Grid.Row>

					<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'10em'}}>
	                <Container style={{backgroundColor:'#FCF1DB'}}/>
	               </Grid.Row >

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>