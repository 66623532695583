import React from 'react';
import HeaderBar from './../../../Components/HeaderBar/HeaderBar';
import { Container, Card, Icon, Image, Table, Header, Progress, Divider, Grid,Button, Radio, Menu} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CollisionDetectionChart from './Components/CollisionDetectionChart';


export default class Collision_Detection extends React.Component {

   constructor(props){
      super(props)
      this.state={
         gravity_strength: null,
         activeItem: 'slow motion'

      }
      this.setToSlowMotion = this.setToSlowMotion.bind(this);
      this.setToCasual = this.setToCasual.bind(this);
      this.setToMedium = this.setToMedium.bind(this);
      this.setToFast = this.setToFast.bind(this);
      this.setToHyperspeed = this.setToHyperspeed.bind(this);
   }


   setToSlowMotion(){
      // let { gravity_strength }  = this.state;
      // let new_gravity_strength = (gravity_strength === .01) ? .2 : .01;
      this.setState({gravity_strength: .0005, activeItem: 'slow motion'});

   }

   setToCasual(){
      // let { gravity_strength }  = this.state;
      // let new_gravity_strength = (gravity_strength === .01) ? .2 : .01;
      this.setState({gravity_strength: .005, activeItem: 'casual'});

   }

   setToMedium(){
      this.setState({gravity_strength: .01, activeItem: 'medium'});

   }

   setToFast(){
      this.setState({gravity_strength: .1, activeItem: 'fast'});

   }

   setToHyperspeed(){
      this.setState({gravity_strength: .2, activeItem: 'hyperspeed'});

   }


   render(){

      // let width =  window.innerWidth;
      // let height = window.innerHeight;
      let { gravity_strength, activeItem }  = this.state;

      gravity_strength = (gravity_strength == null) ? .0005 : gravity_strength;
      activeItem = (activeItem == null) ? 'casual' : activeItem;


      return (
         <Container fluid style={{backgroundColor:'black'}}>
            <Grid columns={2} stackable >
               

              {/* {<Grid.Row columns={3} stretched>
                  <Grid.Column >
                  </Grid.Column>
                  <Grid.Column centered>          
                     <Button.Group>
                      <Button color={(gravity_strength === .005) ? 'white' : 'teal'} onClick={()=>this.setToSlow()}>
                        Slow
                      </Button>
                      <Button color={(gravity_strength === .1) ? 'white' : 'teal'} onClick={()=>this.setToMedium()}>
                        Medium
                      </Button>
                      <Button color={(gravity_strength === .3) ? 'white' : 'teal'} onClick={()=>this.setToFast()}>
                        Fast
                      </Button>
                    </Button.Group>
                  </Grid.Column> 
                  <Grid.Column >
                  </Grid.Column>
               </Grid.Row>}*/}

               <Grid.Column centered width={'3'} style={{backgroundColor:'gray', marginLeft:'1em'}} >
               <Menu fluid centered  inverted vertical style={{backgroundColor:'gray'}}>
                  <Menu.Item
                    name='slow motion'
                    active={activeItem === 'slow motion'}
                    onClick={()=>this.setToSlowMotion()}
                    color={(gravity_strength === .0005) ? 'teal' : 'white'}
                  />
                  <Menu.Item
                    name='casual'
                    active={activeItem === 'casual'}
                    onClick={()=>this.setToCasual()}
                    color={(gravity_strength === .005) ? 'teal' : 'white'}
                  />
                  <Menu.Item
                    name='medium'
                    active={activeItem === 'medium'}
                    onClick={()=>this.setToMedium()}
                    color={(gravity_strength === .1) ? 'teal' : 'white'}
                  />
                  <Menu.Item
                    name='fast'
                    active={activeItem === 'fast'}
                    onClick={()=>this.setToFast()}
                    color={(gravity_strength === .2) ? 'teal' : 'white'}
                  />
                  <Menu.Item
                    name='hyperspeed'
                    active={activeItem === 'hyperspeed'}
                    onClick={()=>this.setToHyperspeed()}
                    color={(gravity_strength === .4) ? 'teal' : 'white'}
                  />
                </Menu>
                 </Grid.Column>
               
               <Grid.Column  >
                <CollisionDetectionChart gravity_strength={gravity_strength}/>
               </Grid.Column>

             <Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'10em'}}>
                <Container style={{backgroundColor:'#FCF1DB'}}/>
               </Grid.Row >


            </Grid>
   
         </Container>
      )
   }

}