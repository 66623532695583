import React from 'react';
import HeaderBar from './../../../Components/HeaderBar/HeaderBar';
import { Container, Card, Icon, Image, Table, Header, Progress, Divider, Grid} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SocialNetworkChart from './Components/SocialNetworkChart';


export default class D3_Visuals extends React.Component {

   constructor(props){
      super(props)
   }


   render(){

      // let width =  window.innerWidth;
      // let height = window.innerHeight;

      return (
         <Container>
            <Grid rows={3} stackable>
               <Grid.Row>
                  <HeaderBar/>
               </Grid.Row>

               <Grid.Row columns={3} stretched>
                  <Grid.Column verticalAlign={'middle'}>
                     <Link style={{fontWeight: 'bold', fontSize: '1.5em', color:'teal'}} to="/project_list"  activeStyle={{color: 'white', pointerEvents: 'none'}}> Back to List </Link>
                  </Grid.Column>
                  <Grid.Column >          
                     <Header as='h4' style={{color: '#a3a3a3', fontSize:'1.75em'}}> Social Network </Header>
                  </Grid.Column> 
                  <Grid.Column >
                  </Grid.Column>
               </Grid.Row>
               
               <SocialNetworkChart/>


            </Grid>
   
         </Container>
      )
   }

}