import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

export default class PrivacyPolicy extends React.Component {

	constructor(props){
		super(props);

		

	}


	render(){


			return (
				<Container >
					<Grid stackable >
						<Grid.Row>
							<HeaderBar />
						</Grid.Row>


						<Grid.Row columns={2} centered  width={'36em'}>
							<Header style={{fontSize: '4em', color: 'black'}} > Privacy Policy </Header>


							<Header style={{fontSize: '1.5em', color: 'black', textAlign:'left', padding:'.2em'}} >
							INTRODUCTION
							</Header>
							
							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} > 
							This privacy notice provides you with details of how your personal data is collected and processed through the use of the site www.jamesramadan.com.

							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >

							By providing your data, you warrant that you are over 13 years of age. </Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >

							Zen Product Management LLC is the data controller and we are responsible for your personal data (referred to as “we”, “us” or “our” in this privacy notice). 
							</Container>


							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Contact Details
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >

								Full name of legal entity: Zen Product Management LLC
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >

								Email address: support@jamesramadan.com
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								If you have inquiries you can email  <b>  support@jamesramadan.com. </b>
 							</Container>



 							<Header style={{fontSize: '1.5em', color: 'black', textAlign:'left', padding:'.2em'}} >
							WHAT DATA DO WE COLLECT
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Your information is kept private with us. We do not sell data to third party vendors. We do not share data with advertisers.

							We respect your privacy regarding any information we may collect through our website. We respect your privacy and are committed to protecting personally identifiable information that you may provide us. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected by us, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through our website and does not apply to our collection of information from other sources.

							This Privacy Policy, together with the Terms and Conditions, set forth the general rules and policies governing your use of our website. Depending on your activities when visiting our website, you may be required to agree to additional terms and conditions.
							</Container>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Website Visitors and Usage
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								We collect various non-personally-identifying information, such as the device type and date and time of visitor requests. The purpose in collecting non-personally identifying information is to better understand how individuals use the website and interact with its content.
							</Container>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Gathering of Personally-Identifying Information
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							We collect potentially personally-identifying information. The amount and type of information that is gathered depends on the nature of the interaction. For example, we ask visitors on our website to provide an email address when purchasing a product, joining a waitlist, or making an inquiry.
							</Container>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Security
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							We take great precautions to store any sensitive data within our application. The security of your information is important to us, but remember that no method of transmission over the internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.
							</Container>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Protection of Certain Personally-Identifying Information
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							We disclose potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on our behalf or to provide services available on our website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using our website, you consent to the transfer of such information to them. We will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, we disclose potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when we believe in good faith that disclosure is reasonably necessary to protect the property or rights of us, third parties or the public at large.
							</Container>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Customer Data 
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Customer Data that includes data relating to any purchases of goods and/or services such as your name, title, billing address, delivery address email address, phone number, contact details, purchase details and your card details. We process this data to supply the goods and/or services you have purchased and to keep records of such transactions. Our lawful ground for this processing is the performance of a contract between you and us and/or taking steps at your request to enter into such a contract.Note: If you make a purchase, you will pay using Stripe (if you use a credit card).
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							In that case, Stripe, the third-party service, is used for payment processing (payment processors). Your payment data is at no point stored on our servers and we can’t see your payment data. Your payment information is provided directly and securely to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							The payment processor we work with is:
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							– Stripe: Their Privacy Policy can be viewed at https://stripe.com/privacy
							</Container>

							<Header style={{fontSize: '1.5em', color: 'black', textAlign:'left', padding:'.2em'}} >
							HOW WE USE THE DATA
							</Header>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Marketing Communications
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							If you are a user of our website and have supplied your email address, we may occasionally send you an email to tell you about new products or features, solicit your feedback, or just keep you up to date with what's going on with our products. If wish to stop all e-mails, a request can be sent to: support@jamesramadan.com and we will process the request.
							</Container>



							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Data Retention
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. For tax purposes the law requires us to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they stop being customers.
							</Container>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Aggregated Statistics
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							We may collect aggregate statistics about users and their usage on the website. We may display this information publicly or provide it to others. However, we do not disclose your personally-identifying information.
							</Container>
							<Header style={{fontSize: '1.5em', color: 'black', textAlign:'left', padding:'.2em'}} >
							PRIVACY POLICY CHANGES
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Although most changes are likely to be minor, we may change our Privacy Policy from time to time, and at our sole discretion. We encourage visitors to frequently check this page for any changes to our Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
							</Container>

						</Grid.Row>

						<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'5em'}}>
		                	<Container style={{backgroundColor:'#FCF1DB'}}/>
		               </Grid.Row >

					
				
					</Grid>
		
				</Container>
			)
		}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>