import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";

import myPDF from './Resume_James_Ramadan.pdf';

export default class CV extends React.Component {

	constructor(props){
		super(props);
		this.handleNavigationClick = this.handleNavigationClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'github': 'https://github.com/jamesramadan',
			'medium': 'https://medium.com/@jamesramadan',
			'twitter': 'https://twitter.com/JamesWRamadan',
			// 'facebook': 'https://www.facebook.com/james.ramadan',
			'linkedin': 'https://www.linkedin.com/in/james-ramadan/',
			'playperformprofit': 'https://www.playperformprofit.com',
			'medical1': 'https://www.ncbi.nlm.nih.gov/pubmed/26018251',
			'medical2': 'https://www.ncbi.nlm.nih.gov/pubmed/23836031',
			'medical3': 'https://www.ncbi.nlm.nih.gov/pubmed/21944825'
		};

		window.open(mapToNativigation[platform]);	
	}


	render(){
		// const shadowMe = '/Images/me/shadow_me2.png';
		// const mePic = '/Images/me/self_pic.jpg';
		const self_head_shot = '/Images/me/self_head_shot.png';

		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>

					<Grid.Row columns={3} centered style={{backgroundColor: '#FCF1DB', minHeight: '3em'}}>
					
					
			                  <Grid.Column verticalAlign={'middle'} textAlign={'center'}>

			                  </Grid.Column>
			                  <Grid.Column verticalAlign={'middle'} textAlign={'center'}>          
			                     <Header as='span' style={{color: 'black', fontSize:'1.75em'}}> Curriculum Vitae (CV) </Header>
			                  </Grid.Column> 
			                  <Grid.Column verticalAlign={'middle'} textAlign={'center'}>
			                  	<Header style={{color: 'teal', fontSize:'1.5em'}} href={myPDF} download="Resume_James_Ramadan.pdf">Download CV</Header>
			                  </Grid.Column>
			        
							
		
					</Grid.Row>

					<Grid.Row columns={3} centered width={'36em'}>

					
						<Grid.Column centered >
						<Transition transitionOnMount={true} animation='bounce' duration={500}>
				
						<Card centered style ={{ backgroundColor: 'gray', border: '1em solid white', width: '30em', minHeight: '32.5em'}}>
							<Image centered size='medium' src={self_head_shot} style ={{ width: '20em'}}/>
				
							{/*<Grid columns={4} stretched centered style ={{ backgroundColor: 'white', minHeight: '8em'}}>

								
								<Grid.Column centered >
									<Popup content='Github' 
										   trigger={
										             <IoLogoGithub onClick={()=>{this.handleNavigationClick('github');}} size={45} color='teal' style={{cursor: 'pointer'}}/>
													} 
									/>
								</Grid.Column>

								<Grid.Column centered >
									<Popup content='Medium' 
										   trigger={
										             <FaMedium onClick={()=>{this.handleNavigationClick('medium');}} size={45} color='teal' style={{cursor: 'pointer'}}/>
													} 
									/>
								</Grid.Column>


								<Grid.Column centered >
									<Popup content='LinkedIn' 
										   trigger={
										             <IoLogoLinkedin onClick={()=>{this.handleNavigationClick('linkedin');}} size={45} color='teal' style={{cursor: 'pointer'}}/>
													} 
									/>
								</Grid.Column>

							</Grid>*/}


					
						</Card>

						</Transition>

						<Transition transitionOnMount={true} animation='bounce' duration={900}>
			            
				
							<Card centered style={{width: '30em', minHeight: '20em'}}>
								    <Card.Content style={{textAlign: 'center'}}>
								      <Card.Header >Skills</Card.Header>
								    </Card.Content>
								    <Card.Content>
								      <Feed>

								        <Feed.Event>
						
								          <Feed.Content>
								         
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              Roadmaps, Prioritization, Market Research and Competitor Analysis, KPIs, Product Analytics, Pricing, GTM, Managing PDLC, 
								              UX/UI, Agile, Jira, Confluence, Miro, Sketch,
								              JavaScript, HTML/CSS, ReactJS, React NativeJS, D3.js, NodeJS, ExpressJS,
											  Python, R, Machine/Deep Learning, NLP, SQL, Data Modelling, Security, IdPs, Docker, Git, Dev Ops, Cloud Computing, AWS, Unix/Linux 
											  

								            </Feed.Summary>
								           
								          </Feed.Content>
								        </Feed.Event>
								      </Feed>
								    </Card.Content>
								</Card>

			
		            	</Transition>
						</Grid.Column>
				

						<Grid.Column stackable centered style={{backgroundColor: '#FCF1DB', minHeight: '40em'}}>
						
							<Transition transitionOnMount={true} animation='bounce' duration={700}>
								<Card centered style={{width: '30em', minHeight: '52em', }}>
								    <Card.Content style={{textAlign: 'center'}}>


								      <Card.Header >

								      <Grid columns={3} stackable>

									      <Grid.Column verticalAlign={'middle'} textAlign={'center'}>

						                  </Grid.Column>
						                  <Grid.Column verticalAlign={'middle'} textAlign={'center'}>          
						                     Experience
						                  </Grid.Column> 
						                  <Grid.Column verticalAlign={'middle'} textAlign={'center'}>
						                  	<Popup content='LinkedIn' 
												   trigger={
												             <IoLogoLinkedin onClick={()=>{this.handleNavigationClick('linkedin');}} size={45} color='teal' style={{cursor: 'pointer'}}/>
															} 
									  		/>
						                  </Grid.Column>

								      </Grid>

									  </Card.Header>

								      
								      
								    </Card.Content>


								    <Card.Content>
								      <Feed>


								      	<Feed.Event>
								          <Feed.Label image='/Images/powerschool_logo.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								              PowerSchool | Product
								            </Feed.Summary>
								            <Feed.Date style={{paddingTop: '.2em'}} content='June 2020 - Present' />
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Improved cross-product security via a modernized authentication system product
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Launched a customer success tool that prevented 75% of troubleshooting tickets for students, teachers, & school staff
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Modernized big data platform for scaling
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Produced an email & SMS communications tool that saved company 200K annually
								            </Feed.Summary>
								          </Feed.Content>
								        </Feed.Event>

								        <Feed.Event>
								          <Feed.Label image='/Images/ppp_apple_logo.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								              Play Perform Profit | Product
								            </Feed.Summary>
								            <Feed.Date style={{paddingTop: '.2em'}} content='March 2018 - May 2020' />
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Launched HR software program to improve employee engagement and retention
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Integrated into restaurant POS systems for real-time analytics reporting
								            </Feed.Summary>
								          </Feed.Content>
								        </Feed.Event>

								        <Feed.Event>
								          <Feed.Label image='/Images/deloitte_icon.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								              Deloitte & Touche LLP | Senior Consultant
								            </Feed.Summary>
								            <Feed.Date style={{paddingTop: '.2em'}} content='March 2014 - March 2018' />
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Led project teams using rapid prototyping and Agile methodology
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Engineered business intelligence products into production
								            </Feed.Summary>
								          </Feed.Content>
								        </Feed.Event>

								        <Feed.Event>
								          <Feed.Label image='/Images/sallie_mae.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								              Sallie Mae | Business Analyst
								            </Feed.Summary>
								            <Feed.Date style={{paddingTop: '.2em'}} content='October 2013 - March 2014' />
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              - Designed SQL queries using Oracle databases and to provide audit analysis
								            </Feed.Summary>
								          </Feed.Content>
								        </Feed.Event>



								      </Feed>
								    </Card.Content>
								</Card>
			            	</Transition>

		            	</Grid.Column>

		            	<Grid.Column centered style={{backgroundColor: '#FCF1DB', minHeight: '44em'}} >

		            	
						<Transition transitionOnMount={true} animation='bounce' duration={900}>
			            
				
							<Card centered style={{width: '30em'}}>
								    <Card.Content style={{textAlign: 'center'}}>
								      <Card.Header >Education</Card.Header>
								    </Card.Content>
								    <Card.Content style={{minHeight: '28.5em'}}>
								      <Feed>

								        <Feed.Event>
								          <Feed.Label image='/Images/northwestern.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								             Northwestern | Masters in Data Science
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              Relevant Coursework: Predictive Analytics, Regression and Multi Analysis, 
								              Practical Machine Learning, Database Systems
								            </Feed.Summary>
								          </Feed.Content>
								        </Feed.Event>

								        <Feed.Event>
								          <Feed.Label image='/Images/virginia.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								             University of Virginia | Bachelor of Science in Statistics & Biology
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              Relevant Coursework: Applied Linear Models, Applied Multivariate Statistics
								            </Feed.Summary>
								          </Feed.Content>
								        </Feed.Event>

								        <Feed.Event>
								          <Feed.Label image='/Images/tj.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								             Thomas Jefferson High School for Science & Technology
								            </Feed.Summary>
								            
								          </Feed.Content>
								        </Feed.Event>


								        <Feed.Event>
								          <Feed.Label image='/Images/hack_reactor.png' />
								          <Feed.Content>
								          	<Feed.Summary style={{fontWeight: 'bold'}}>
								             Hack Reactor | Computer Science Fundamentals
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              Relevant Coursework: Fundamentals of Computer Science, Full-Stack JavaScript
								            </Feed.Summary>
								          </Feed.Content>
								        </Feed.Event>


								      </Feed>
								    </Card.Content>
								</Card>

			
		            	</Transition>

		            	<Transition transitionOnMount={true} animation='bounce' duration={900}>
			            
				
							<Card centered style={{width: '30em', minHeight: '17em'}}>
								    <Card.Content style={{textAlign: 'center'}}>
								      <Card.Header >Publications</Card.Header>
								    </Card.Content>
								    <Card.Content>
								      <Feed>

								        <Feed.Event>
						
								          <Feed.Content>
								         
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              The central role of calcium in the effects of cytokines on beta-cell function: implications for type 1 and type 2 diabetes--Cell Calcium
								              <div onClick={()=>{this.handleNavigationClick('medical3');}} style={{display: 'inline-block', marginLeft: '0.5em'}} >
			            				 	    <span style={{cursor: 'pointer', color: 'teal'}}> Read</span>
			            					  </div>
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              An Islet-Targeted Genome-Wide Association Scan Identifies Novel Genes Implicated in Cytokine-Mediated Islet Stress in Type 2 Diabetes--Endocrinology
								              <div onClick={()=>{this.handleNavigationClick('medical1');}} style={{display: 'inline-block', marginLeft: '0.5em'}} >
			            				 	    <span style={{cursor: 'pointer', color: 'teal'}}> Read</span>
			            					  </div>
								            </Feed.Summary>
								            <Feed.Summary style={{padding: '.25em', fontSize:'.9em', color:'#4C5454'}}>
								              Circulating levels of IL-1B+IL-6 cause ER stress and dysfunction in islets from prediabetic male mice--Endocrinology
								              <div onClick={()=>{this.handleNavigationClick('medical2');}} style={{display: 'inline-block', marginLeft: '0.5em'}} >
			            				 	    <span style={{cursor: 'pointer', color: 'teal'}}> Read</span>
			            					  </div>
								            </Feed.Summary>
								           
								          </Feed.Content>
								        </Feed.Event>
								      </Feed>
								    </Card.Content>
								</Card>

			
		            	</Transition>
		            	</Grid.Column>
						



					</Grid.Row>

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>