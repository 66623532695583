import React from 'react';
import HeaderBar from './../../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";
import { Link } from 'react-router-dom';

export default class Publications extends React.Component {

	constructor(props){
		super(props);
		this.handleNavigationClick = this.handleNavigationClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'medium-1': 'https://medium.com/swlh/want-to-keep-your-restaurant-employees-you-must-do-these-3-things-to-succeed-641ed9e672be',
			'medium-2': 'https://medium.com/swlh/the-top-5-ways-to-optimize-the-talent-pipeline-in-your-organization-62d89db69419',
			'medical1': 'https://www.ncbi.nlm.nih.gov/pubmed/26018251',
			'medical2': 'https://www.ncbi.nlm.nih.gov/pubmed/23836031',
			'medical3': 'https://www.ncbi.nlm.nih.gov/pubmed/21944825',
		};
		window.open(mapToNativigation[platform]);	
	}


	render(){
		const jamesPic = '/Images/me/james_looking_out.png';
		const mePic = '/Images/me/me_hiking.png';

		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>		


					<Grid.Row stackable columns={2} centered width={'36em'}>

					  <Grid.Column centered stretched>

					  	<Transition transitionOnMount={true} animation='slide right' duration={500}>
			            	<Segment textAlign={'left'} style={{backgroundColor: 'black'}}>
			            		<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:'teal'}} to="/project_list"  activeStyle={{color: 'white', pointerEvents: 'none'}}> Back to List </Link>
			            		<Header style={{fontSize: '1.75em', color: 'white'}} > Medical Journals </Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'white'}} > 
			            			The central role of calcium in the effects of cytokines on beta-cell function: implications for type 1 and type 2 diabetes-- 
			            			<div onClick={()=>{this.handleNavigationClick('medical3');}} style={{display: 'inline-block'}} >
			            		
			            			 	<span style={{cursor: 'pointer', color: 'teal'}}>Cell Calcium</span>
			            			</div>
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'white'}} > 
			            			An Islet-Targeted Genome-Wide Association Scan Identifies Novel Genes Implicated in Cytokine-Mediated Islet Stress in Type 2 Diabetes-- 
			            			<div onClick={()=>{this.handleNavigationClick('medical1');}} style={{display: 'inline-block'}} >
			            		
			            				<span style={{cursor: 'pointer', color: 'teal'}}>Endocrinology</span>
			            			</div>
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'white'}} > 
			            			Circulating levels of IL-1B+IL-6 cause ER stress and dysfunction in islets from prediabetic male mice--  
			            			<div onClick={()=>{this.handleNavigationClick('medical2');}} style={{display: 'inline-block'}} >
			            				
			            				 <span style={{cursor: 'pointer', color: 'teal'}}>Endocrinology</span>
			            			</div>
			            		</Header>

			            
			            	</Segment>

		            	</Transition>
		            

		            	</Grid.Column>


		            	<Grid.Column centered stretched>
		            	<Card centered style ={{ backgroundColor: 'gray', border: '.25em solid gray', width: '40em',}}>
		            		<Transition transitionOnMount={true} animation='slide right' duration={700}>	
								<Image verticalAlign='middle' centered size='big' src={mePic} />
							</Transition>
						</Card>
						</Grid.Column>
						



					</Grid.Row>

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>