import React from 'react';
import * as d3 from 'd3';
// import {scaleLinear} from 'd3-scale';
// import {max} from 'd3-array';
import {select} from 'd3-selection';


export default class LineChart extends React.Component {
   constructor(props){
      super(props)
      this.createBarChart = this.createBarChart.bind(this)
   }
   componentDidMount() {
      this.createBarChart()
   }
   // componentDidUpdate() {
   //    this.createBarChart()
   // }

   componentWillUnmount(){
    let node =this.node;
      select(node).remove();
    d3.selectAll("svg").remove();
      //select(this.state.element).remove if re-render after connecting to api real data will have to unmount. create ref to element in state first
   }

   createBarChart() {
  //   var margin = {top: 50, right: 50, bottom: 50, left: 50}
  // , width = window.innerWidth - margin.left - margin.right // Use the window's width 
  // , height = window.innerHeight - margin.top - margin.bottom; // Use the window's height
  const ref = this.ref;     

      
      var width = 500;
      var height = 300;
   // var margin = {top: 20, right: 20, bottom: 60, left: 80}
   const margin = {top: 50, right: 160, bottom: 60, left: 150}


// The number of datapoints
var n = 10;

// 5. X scale will use the index of our data
var xScale = d3.scaleLinear()
    .domain([0, n-1]) // input
    .range([0, width]); // output

// 6. Y scale will use the randomly generate number 
var yScale = d3.scaleLinear()
    .domain([0, 100]) // input 
    .range([height, 0]); // output 

// 7. d3's line generator
var line = d3.line()
    .x(function(d, i) { return (xScale(i)+ margin['left']); }) // set the x values for the line generator
    .y(function(d) { return yScale(d.y); }) // set the y values for the line generator 
    .curve(d3.curveMonotoneX) // apply smoothing to the line

// 8. An array of objects of length N. Each object has key -> value pair, the key being "y" and the value is a random number
var dataset = d3.range(n).map(function(d) { return {"y": Math.round(d3.randomUniform(100)(), 2) } })

// 1. Add the SVG to the page and employ #2
var svg = d3.select("body").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

// 3. Call the x axis in a group tag
svg.append("g")
    .attr("class", "x axis")
    // .attr("transform", "translate(0," + height + ")")
    .attr('transform', `translate(${(margin['left'])},${height})`)
    .style("stroke","white")
    .call(d3.axisBottom(xScale)); // Create an axis component with d3.axisBottom

     svg.append("g")
         // .attr("class", "axis axis--x")
         .attr("transform", `translate(${width},` + ( height + margin['bottom']) + ")")
         // .attr("transform", translate(`${width}`, `${height}` )

         .call(d3.axisBottom(xScale));

svg.append("text")             
      .attr("transform",
            "translate(" + ((width/2) + margin['left']) + " ," + 
                           (height + margin.top ) + ")")
      .style("text-anchor", "middle")
      .style("stroke","white")
      .text("Rainfall (Inch.)");

// 4. Call the y axis in a group tag
svg.append("g")
    .attr("class", "y axis")
     .attr('transform', `translate(${(margin['left'])},${0})`)
    .style("stroke","white")
    .call(d3.axisLeft(yScale)); // Create an axis component with d3.axisLeft

svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", margin['left'] - 70)
      .attr("x",0 - (height / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("stroke","white")
      .text("Temperature (Deg F)"); 

// 9. Append the path, bind the data, and call the line generator 
svg.append("path")
    .datum(dataset) // 10. Binds data to the line 
    .attr("class", "line") // Assign a class for styling 
    .attr("d", line); // 11. Calls the line generator 

var div = d3.select("body").append("div")
    .attr("class", "tooltip")
    .style("display","inline-block")
    .style("opacity", 0);

// 12. Appends a circle for each datapoint 
svg.selectAll(".dot")
    .data(dataset)
  .enter().append("circle") // Uses the enter().append() method
    .attr("class", "dot") // Assign a class for styling
    .attr("cx", function(d, i) { return (xScale(i)+ margin['left']) })
    .attr("cy", function(d) { return yScale(d.y) })
    .attr("r", 10)
    .style("cursor", "pointer")
    .on("mouseover", function(d,i) {
       div.transition()
         .duration(200)
         .style("opacity", 1);
       div.html('Rainfall: ' + i + "<br/>" + 'Temp.: ' + d.y + '')
         .style("left", (d3.event.pageX) + "px")
         .style("top", (d3.event.pageY - 50) + "px")
         .style("stroke","black");
       })
     .on("mouseout", function(d) {
       div.transition()
         .duration(500)
         .style("opacity", 0);
       });
      

 d3.select(".line")
 .style("stroke","white");

 d3.selectAll(".dot")
 .style("fill","teal");

 d3.selectAll("div.tooltip")
 .style("position","absolute")
 .style("text-align","center")
 .style("width","100px")
 .style("height","40px")
 .style("padding","2px")
 .style("font","16px sans-serif")
 .style("color","black")
 .style("background","white")
 .style("border","0px")
 .style("border-radius","8px")
 .style("pointer-events","none")


    }
    render(){
        return (
            <svg ref={node => this.node = node} width={0} height={0}
        >
            </svg>
        )
    }

}