import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed, List, Button, TransitionablePortal, Pagination} from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';

import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";


export default class Cancel_Purchase_Component extends React.Component {

	constructor(props){
		super(props);

		this.handleNavigationClick = this.handleNavigationClick.bind(this);
		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handlePaginationChange = this.handlePaginationChange.bind(this);

	

	}



	render(){
		// const jamesPic = '/Images/me/james_looking_out.png';
		const mePic = '/Images/me/me.png';
		const ZPM_Book_Cover = '/Images/eBook/ZPM_Book_Cover_2.png';
		const Table_Of_Contents_1 = '/Images/eBook/Table_Of_Contents_1.png';
		const Table_Of_Contents_2 = '/Images/eBook/Table_Of_Contents_2.png';
		const Problem_Discovery = '/Images/eBook/Problem_Discovery.png';
		const { open, activePage } = this.state


		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>




					<Grid.Row stackable columns={2} centered width={'36em'}>

					  <Grid.Column centered stretched>

					  	<Transition transitionOnMount={true} animation='slide right' duration={500}>
			            	<Container textAlign={'left'} style={{backgroundColor: 'white', padding:'1em'}}>

			            	
			            		<Header style={{fontSize: '1.75em', color: 'black'}} > Zen Product Management (eBook)</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			Want to create your own products or become a better product manager?
			            		</Header>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			If so, this 100+ page eBook is for you.
			            		</Header>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			Zen Product Management walks through the fundamentals of how to ideate, create, and launch 
			            			new products. It contains relavent product examples and tidbits from
			            			my own personal experiences. After reading the book, you'll be better equipped to 
			            			turn your product ideas into a reality.
			            		</Header>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 

			            			Who this book can help:
			            		</Header>
			            		<List bulleted>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Aspiring, new or young product managers who want to improve their product skills and knowledge
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Entrepreneurs who want to launch new products
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Someone who wants to learn more about the product manager role & responsibilities
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Someone who has aspirations to build new products one day, especially technology products
			            		</List.Item>
			            		</List>

			            		<h1>Thanks for your order!</h1>
							    <p>
							      We appreciate your business!
							      If you have any questions, please email
							      <a href="mailto:orders@example.com">orders@example.com</a>.
							    </p>

			

			            	</Container>

		            	</Transition>
		            

		            	</Grid.Column>

		            


		            	<Grid.Column centered stretched>
		            	<Container centered style ={{ width: '30em',}}>

		            		<Transition transitionOnMount={true} animation='slide right' duration={700}>
		            		<Container>
		            		<TransitionablePortal
						        closeOnTriggerClick
						        onOpen={this.handleOpen}
						        onClose={this.handleClose}
						        openOnTriggerClick
						        trigger={
						          	
									<Image verticalAlign='middle' centered size='large' style= {{cursor:'pointer'}} src={ZPM_Book_Cover} />
								
						        }
						      >
						        <Segment
						          style={{ left: '16%', position: 'fixed', top: '12%', zIndex: 1000, width:'40em', backgroundColor:'white'}}
						        >
						          <Grid >
						          <Grid.Row centered>
						          	{(activePage == 1) &&
						          		<Image verticalAlign='middle' centered style={{  height:'30em'}} size='medium' src={ZPM_Book_Cover} />
						            }
						          	{(activePage == 2) &&
						          		<Image verticalAlign='middle' centered style={{  height:'30em'}} size='medium' src={Table_Of_Contents_1} />
						            }
						            {(activePage == 3) &&
						          		<Image verticalAlign='middle' centered style={{  height:'30em'}} size='medium' src={Table_Of_Contents_2} />
						            }
						            {(activePage == 4) &&
						          		<Image verticalAlign='middle' centered style={{  height:'30em'}} size='medium' src={Problem_Discovery} />
						            }
						          </Grid.Row>
						          <Grid.Row centered>
							          <Pagination
									    boundaryRange={0}
									    ellipsisItem={null}
									    firstItem={null}
									    lastItem={null}
									    siblingRange={1}
									    totalPages={4}
									    activePage={activePage}
            							onPageChange={this.handlePaginationChange}
									  />
								  </Grid.Row>
								  </Grid>
						        </Segment>
						    </TransitionablePortal>
						    </Container>
						    </Transition>
		            		
						</Container>
						<Container centered style ={{ height: '2em',}}>
		            		
						</Container>
						<Container style ={{width: '30em', height:'10em'}}>
							<Grid centered columns={1}>


								
								
							
								<Grid.Column width={10} centered>
									<Transition transitionOnMount={true} animation='bounce' duration={500}>
									{/*<Button  centered size={'massive'} style ={{ minWidth:'10em', color:'#D35134'}} onClick={()=>{this.handleNavigationClick('medium-1');}}>*/}
					            			 	<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:'red', textAlign:'center'}} to="/checkout_form" activeStyle={{color: 'white', pointerEvents: 'none'}}>Buy Now</Link>
					          {/*  	</Button>*/}
					            	</Transition>
								</Grid.Column>

							
							

							
			            	</Grid>
			            </Container>
						</Grid.Column>
						



					</Grid.Row>
					

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>