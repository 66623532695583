import React from 'react';
import * as d3 from 'd3';
// import {scaleLinear} from 'd3-scale';
// import {max} from 'd3-array';
import {select} from 'd3-selection';


export default class BarChart extends React.Component {
   constructor(props){
      super(props)
      this.createBarChart = this.createBarChart.bind(this)
   }
   componentDidMount() {
      this.createBarChart()
   }
   // componentDidUpdate() {
   //    this.createBarChart()
   // }

   componentWillUnmount(){
      let ref =this.ref;
      select(ref).remove();
      d3.selectAll("svg").remove();
      // if re-render after connecting to api real data will have to unmount. create ref to element in state first
   }

   createBarChart() {
      const ref = this.ref;


      var svg = d3.select("svg").style("background-color", 'white')

     

      
      var width = svg.attr("width");
      var height = svg.attr("height") ;


      

      var nodes_data =  [
        {"name": "Lillian", "sex": "F"},
        {"name": "Gordon", "sex": "M"},
        {"name": "Sylvester", "sex": "M"},
        {"name": "Mary", "sex": "F"},
        {"name": "Helen", "sex": "F"},
        {"name": "Jamie", "sex": "M"},
        {"name": "Jessie", "sex": "F"},
        {"name": "Ashton", "sex": "M"},
        {"name": "Duncan", "sex": "M"},
        {"name": "Evette", "sex": "F"},
        {"name": "Mauer", "sex": "M"},
        {"name": "Fray", "sex": "F"},
        {"name": "Duke", "sex": "M"},
        {"name": "Baron", "sex": "M"},
        {"name": "Infante", "sex": "M"},
        {"name": "Percy", "sex": "M"},
        {"name": "Cynthia", "sex": "F"},
        {"name": "Feyton", "sex": "M"},
        {"name": "Lesley", "sex": "F"},
        {"name": "Yvette", "sex": "F"},
        {"name": "Maria", "sex": "F"},
        {"name": "Lexy", "sex": "F"},
        {"name": "Peter", "sex": "M"},
        {"name": "Ashley", "sex": "F"},
        {"name": "Finkler", "sex": "M"},
        {"name": "Damo", "sex": "M"},
        {"name": "Imogen", "sex": "F"}
        ]

    //Sample links data 
    //type: A for Ally, E for Enemy
    var links_data = [
      {"source": "Sylvester", "target": "Gordon", "type":"A" },
        {"source": "Sylvester", "target": "Lillian", "type":"A" },
        {"source": "Sylvester", "target": "Mary", "type":"A"},
        {"source": "Sylvester", "target": "Jamie", "type":"A"},
        {"source": "Sylvester", "target": "Jessie", "type":"A"},
        {"source": "Sylvester", "target": "Helen", "type":"A"},
        {"source": "Helen", "target": "Gordon", "type":"A"},
        {"source": "Mary", "target": "Lillian", "type":"A"},
        {"source": "Ashton", "target": "Mary", "type":"A"},
        {"source": "Duncan", "target": "Jamie", "type":"A"},
        {"source": "Gordon", "target": "Jessie", "type":"A"},
        {"source": "Sylvester", "target": "Fray", "type":"E"},
        {"source": "Fray", "target": "Mauer", "type":"A"},
        {"source": "Fray", "target": "Cynthia", "type":"A"},
        {"source": "Fray", "target": "Percy", "type":"A"},
        {"source": "Percy", "target": "Cynthia", "type":"A"},
        {"source": "Infante", "target": "Duke", "type":"A"},
        {"source": "Duke", "target": "Gordon", "type":"A"},
        {"source": "Duke", "target": "Sylvester", "type":"A"},
        {"source": "Baron", "target": "Duke", "type":"A"},
        {"source": "Baron", "target": "Sylvester", "type":"E"},
        {"source": "Evette", "target": "Sylvester", "type":"E"},
        {"source": "Cynthia", "target": "Sylvester", "type":"E"},
        {"source": "Cynthia", "target": "Jamie", "type":"E"},
        {"source": "Mauer", "target": "Jessie", "type":"E"},
        {"source": "Duke", "target": "Lexy", "type":"A"},
        {"source": "Feyton", "target": "Lexy", "type":"A"},
        {"source": "Maria", "target": "Feyton", "type":"A"},
        {"source": "Baron", "target": "Yvette", "type":"E"},
        {"source": "Evette", "target": "Maria", "type":"E"},
        {"source": "Cynthia", "target": "Yvette", "type":"E"},
        {"source": "Maria", "target": "Jamie", "type":"E"},
        {"source": "Maria", "target": "Lesley", "type":"E"},
        {"source": "Ashley", "target": "Damo", "type":"A"},
        {"source": "Damo", "target": "Lexy", "type":"A"},
        {"source": "Maria", "target": "Feyton", "type":"A"},
        {"source": "Finkler", "target": "Ashley", "type":"E"},
        {"source": "Sylvester", "target": "Maria", "type":"E"},
        {"source": "Peter", "target": "Finkler", "type":"E"},
        {"source": "Ashley", "target": "Gordon", "type":"E"},
        {"source": "Maria", "target": "Imogen", "type":"E"}     
    ]

    nodes_data.forEach((individual)=>{
        individual['connections'] = 0;
        links_data.forEach((link)=>{
          if(individual['name'] === link['source'] || individual['name'] === link['target'] ){
            individual['connections'] = individual['connections'] + 1;
          }
        })
    })


    //set up the simulation and add forces  
    var simulation = d3.forceSimulation()
              .nodes(nodes_data);
                                  
    var link_force =  d3.forceLink(links_data)
                            .id(function(d) { return d.name; });            
             
    var charge_force = d3.forceManyBody()
        .strength(-200); 
        
    var center_force = d3.forceCenter(width / 3, height / 2);  
                          
    simulation
        .force("charge_force", charge_force)
        .force("center_force", center_force)
        .force("links",link_force)
     ;

            
    //add tick instructions: 
    simulation.on("tick", tickActions );

    //add encompassing group for the zoom 
    var background = svg.append("g")
        .attr("class", "everything");


    var connections_name_box = background.append("text")
      .attr("x", 100)
      .attr("y", 50)

    var connections_number_box = background.append("text")
      .attr("x", 100)
      .attr("y", 80)

    //draw lines for the links 
    var link = background.append("g")
          .attr("class", "links")
          .selectAll("line")
          .data(links_data)
          .enter().append("line")
          .attr("stroke-width", 1)
          .style("stroke", linkColour);        

    //draw circles for the nodes 
    // var node = g.append("g")
    //         .attr("class", "nodes") 
    //         .selectAll("circle")
    //         .data(nodes_data)
    //         .enter()
    //         .append("circle")
    //         .attr("r", radius)
    //         .attr("stroke", circleColour)
    //         .attr("fill", 'teal')
    //         .attr("class", "node")


    var nodes = background.append("g")
            .attr("class", "nodes") 
            

    var node = nodes.selectAll("g")
            .data(nodes_data)
            .enter()
            .append("g")
            .style("cursor", "grab");

     // var radius = 25; 

     let colors = d3.scaleOrdinal(d3.schemeCategory10);
     let scale_factor = 4;

     var circle = node.append("circle")
            .attr("r", function (d) { return (d.connections * scale_factor); })
            .attr("stroke", circleColour)
            .attr("fill", function(d,i){return colors(i)})
            .attr("class", "node")
            .text(function (d) { return d.name; })
            .style("text-anchor", "middle")
            .style("font-family", "Arial")
            .style("font-size", 18)
            // .attr("dx", 12)
           // .attr("dy", "1em")


      var text = node.append("text")
      .text(function (d) { return (d.name); })
      .style("text-anchor", "middle")
      .style("font-family", "Arial")
      .style("stroke", 'black')
      .style("font-size", 12);



//      var node = svg.selectAll("g")
//                 .data(nodes_data)
//               .enter().append("g")
//                 .attr("class", "node")
//                 // .call(force.drag);

    // node2.append("circle")
    // .attr("r", 4.5);

// var text = node.append("text")
//     // .attr("dx", 12)
//     // .attr("dy", "1em")
//     .text(function (d) { return d.name; })
//     .style("text-anchor", "middle")
//     .style("fill", "white")
//     .style("font-family", "Arial")
//     .style("font-size", 18);

//   node2.append("text")
//         .attr("dx", function(d){return -20})
//         .text(function(d){return d.name})

// const margin = {top: 0, right: 0, bottom: 0, left: 0}
// // 5. X scale will use the index of our data
// var xScale = d3.scaleLinear()
//     .domain([0, n-1]) // input
//     .range([0, width]); // output

// // 6. Y scale will use the randomly generate number 
// var yScale = d3.scaleLinear()
//     .domain([0, 100]) // input 
//     .range([height, 0]); // output 

// var n = 10;

// var div = d3.select("body").append("div")
//     .attr("class", "tooltip")
//     .style("display","inline-block")
//     .style("opacity", 0);

// nodes.selectAll(".dot")
//     .data(nodes_data)
//   .enter().append("circle") // Uses the enter().append() method
//     .attr("class", "dot") // Assign a class for styling
//     .attr("cx", function(d, i) { return (xScale(i)+ margin['left']) })
//     .attr("cy", function(d) { return yScale(d.y) })
//     .attr("r", 10)
//     .style("cursor", "pointer")
//     .on("mouseover", function(d,i) {
//        div.transition()
//          .duration(200)
//          .style("opacity", 1);
//        div.html('Rainfall: ' + i + "<br/>" + 'Temp.: ' + d.y + '')
//          .style("left", (d3.event.pageX) + "px")
//          .style("top", (d3.event.pageY - 50) + "px")
//          .style("stroke","black");
//        })
//      .on("mouseout", function(d) {
//        div.transition()
//          .duration(500)
//          .style("opacity", 0);
//        });
      

//  d3.select(".line")
//  .style("stroke","black");

//  d3.selectAll(".dot")
//  .style("fill","teal");

//  d3.selectAll("div.tooltip")
//  .style("position","absolute")
//  .style("text-align","center")
//  .style("width","100px")
//  .style("height","40px")
//  .style("padding","2px")
//  .style("font","16px sans-serif")
//  .style("color","black")
//  .style("background","white")
//  .style("border","0px")
//  .style("border-radius","8px")
//  .style("pointer-events","none")




     
     
    //add drag capabilities  
    var drag_handler = d3.drag()
      .on("start", drag_start)
      .on("drag", drag_drag)
      .on("end", drag_end); 
      
    drag_handler(node)



    //add zoom capabilities 
    var zoom_handler = d3.zoom()
        .on("zoom", zoom_actions)

    zoom_handler(svg);    

    /** Functions **/

    //Function to choose what color circle we have
    //Let's return blue for males and red for females
    function circleColour(d){
      // if(d.sex =="M"){
      //   return "teal";
      // } else {
      //   return "pink";
      // }
      return 'black';
    }

    //Function to choose the line colour and thickness 
    //If the link type is "A" return green 
    //If the link type is "E" return red 
    function linkColour(d){
      // if(d.type == "A"){
      //   return "green";
      // } else {
      //   return "red";
      // } 
      return 'grey';
    }

    //Drag functions 
    //d is the node 
    function drag_start(d) {
     if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;

        connections_name_box
          .text(d.name)
          .style("text-anchor", "middle")
          .style("font-family", "Arial")
          .style("stroke", 'teal')
          .style("fill", 'gray')
          .style("font-size", 36);

        connections_number_box
          .text(d.connections) 
          .style("text-anchor", "middle")
          .style("font-family", "Arial")
          .style("stroke", 'teal')
          .style("fill", 'gray')
          .style("font-size", 36);

      node.style("cursor", "grabbing");
           
    }

    //make sure you can't drag the circle outside the box
    function drag_drag(d) {
      // console.log('d3event', d3.event.x)
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }

    function drag_end(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;

      node.style("cursor", "grab");
    }

    //Zoom functions 
    function zoom_actions(){
        background.attr("transform", d3.event.transform)
    }


    function tickActions() {
        //update circle positions each tick of the simulation 
           node
            .attr("cx", function(d) { return d.x; })
            .attr("cy", function(d) { return d.y; })

          circle
            .attr("cx", function(d) { return d.x; })
            .attr("cy", function(d) { return d.y; });

          text
            .attr("dx", function(d) { return d.x; })
            .attr("dy", function(d) { return d.y; });

          // text
          //   .attr("dx", function(d) { return d.x; })
          //   .attr("dy", function(d) { return d.y; });
            
        //update link positions 
        link
            .attr("x1", function(d) { return d.source.x; })
            .attr("y1", function(d) { return d.source.y; })
            .attr("x2", function(d) { return d.target.x; })
            .attr("y2", function(d) { return d.target.y; });
    } 

   
   }

	render(){
		return (
			<svg style={{border: '.1em solid teal'}} width={window.innerWidth} height={window.innerHeight / 2} ref={ref => this.ref = ref}
      	>
      		</svg>
		)
	}

}