import React from 'react';
import moment from 'moment';

import { NavLink, useNavigate, Link } from 'react-router-dom'

import { Container, Segment, Grid, Header, Image, Transition, Menu, Dropdown, Card} from 'semantic-ui-react';

export default class HeaderBar extends React.Component {
    constructor(props){
      super(props)

      // this.state={
      //    activeItem: 'home'

      // }

      // this.handleItemClick = this.handleItemClick.bind(this);

    }

    // handleItemClick = (activeItem) => {

    // 	console.log('here' , activeItem)

    // 	this.setState({ activeItem: activeItem })

    // }

 

	render(){

		const logo = '/Images/companylogos/ppp_banner.png';
		const ensoLogo = '/Images/white_enso.png';
		const yinYangLogo = '/Images/yin_yang.svg';

		let current_date = moment().format('MMMM DD, YYYY');

		let current_pathname = window.location.pathname;
		if(current_pathname == '/'){
			current_pathname = '/landing';
		}

		const onLandingAlready = (current_pathname == '/landing') ? true : false;

		const menu_not_selected_color = 'teal';
		const menu_selected_color = 'black';
	

		return (
			<Container className="App-header" style={{backgroundColor: 'white',  marginTop: '1em'}}>
				
					{/*<div style={{display: 'inline-block', float:'left', color:'#ffc96c', paddingTop: '2vh', marginLeft: '2vh'}} className="dateHeaderFont">
		        		<span> {current_date} </span>
		            </div>*/}
		            <Grid centered columns={1 } style={{border:'0em', backgroundColor: '#FCF1DB'}} >
			         	<Grid.Column stetched width={3} centered style={{minHeight:'5em', border:'0em'}}  >
			         
				            	{/*{ onLandingAlready &&
				            		<Image  centered verticalAlign='middle' size='tiny' src={ensoLogo}   />
				            	}
				            	{ !onLandingAlready &&
				            		<Link to="/landing"> <Image  centered verticalAlign='middle' size='tiny' src={ensoLogo}   /> </Link>
				            	}*/}

				            	{ onLandingAlready &&
				            		<Card fluid wrapped style={{fontWeight: 'bold', fontSize: '1.5em', textAlign:'center', color:'black', }} borderless>
				            		<Card.Content >
				            			James Ramadan
				            		</Card.Content>
				            		</Card>
				            	}
				            	{ !onLandingAlready &&
				            		<Link  to="/landing" style={{fontWeight: 'bold', fontSize: '1.5em', textAlign:'center', color:menu_not_selected_color, }} activeStyle={{color: 'white', pointerEvents: 'none'}} borderless>
				            			<Card fluid wrapped >
				            				<Card.Content> James Ramadan 
				            				</Card.Content>
				            			</Card>
				            		</Link>
				            	}

				            	

	
			            </Grid.Column>

		
			            <Grid.Column centered stretched width={10} style={{minHeight:'5em'}}>

			            				<Grid relaxed centered columns={4} textAlign={'center'}>

			            					<Grid.Row >


			            					
			            					<Menu style={{backgroundColor:'white'}} borderless fluid>
				            					
				            	
								            	
			            						<Menu.Item style={{backgroundColor:'white', }} position= {'right'} >
						
								            		
								            		{((current_pathname !== '/about_me') && (current_pathname !== '/cv') && (current_pathname !== '/project_list')) &&
									            		<Dropdown simple item style={{backgroundColor:'white', fontWeight: 'bold', fontSize: '1.5em', color:menu_not_selected_color}} text='About'>
												          <Dropdown.Menu style={{backgroundColor:'white'}}>
												            <Dropdown.Item><Link style={{fontSize: '1em', color:'teal'}} to="/about_me" activeStyle={{color: 'white', pointerEvents: 'none'}}>Bio</Link></Dropdown.Item>
												            <Dropdown.Item><Link style={{fontSize: '1em', color:'teal'}} to="/cv" activeStyle={{color: 'white', pointerEvents: 'none'}}>CV/Resume</Link></Dropdown.Item>
												            <Dropdown.Item ><Link style={{fontSize: '1em', color:'teal'}} to="/project_list" activeStyle={{color: 'white', pointerEvents: 'none'}}>Projects</Link></Dropdown.Item>
												          </Dropdown.Menu>
												        </Dropdown>
											    	}

											    	{ ((current_pathname == '/about_me') || (current_pathname == '/cv') || (current_pathname == '/project_list')) &&
									            		<Dropdown simple item style={{backgroundColor:'white', fontWeight: 'bold', fontSize: '1.5em', color:menu_selected_color}} text='About'>
												          <Dropdown.Menu style={{backgroundColor:'white'}}>
												            
												            {(current_pathname !== '/about_me') &&
												            	<Dropdown.Item><Link style={{fontSize: '1em', color:menu_not_selected_color}} to="/about_me" activeStyle={{color: 'white', pointerEvents: 'none'}}>Bio</Link></Dropdown.Item>
												            }
												            {(current_pathname == '/about_me') &&
												            	<Dropdown.Item><Link style={{fontSize: '1em', color:menu_selected_color}} to="/about_me" activeStyle={{color: 'white', pointerEvents: 'none'}}>Bio</Link></Dropdown.Item>
												            }
												            {(current_pathname !== '/cv') &&
												            	<Dropdown.Item><Link style={{fontSize: '1em', color:menu_not_selected_color}} to="/cv" activeStyle={{color: 'white', pointerEvents: 'none'}}>CV/Resume</Link></Dropdown.Item>
												            }
												            {(current_pathname == '/cv') &&
												            	<Dropdown.Item><Link style={{fontSize: '1em', color:menu_selected_color}} to="/cv" activeStyle={{color: 'white', pointerEvents: 'none'}}>CV/Resume</Link></Dropdown.Item>
												            }

												            
												            {(current_pathname !== '/project_list') &&
												            	<Dropdown.Item><Link style={{fontSize: '1em', color:menu_not_selected_color}} to="/project_list" activeStyle={{color: 'white', pointerEvents: 'none'}}>Projects</Link></Dropdown.Item>
												            }
												            {(current_pathname == '/project_list') &&
												            	<Dropdown.Item><Link style={{fontSize: '1em', color:menu_selected_color}} to="/project_list" activeStyle={{color: 'white', pointerEvents: 'none'}}>Projects</Link></Dropdown.Item>
												            }
												          
												          </Dropdown.Menu>
												        </Dropdown>
											    	}
										
								            
								            	</Menu.Item>

								            	<Menu.Item style={{backgroundColor:'white', paddingRight:'2em'}} position= {'right'}  activeStyle={{backgroundColor: 'white', pointerEvents: 'none'}}>
									            	
									            		{(current_pathname !== '/blogs') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_not_selected_color, textAlign:'center'}} to="/blogs" activeStyle={{color: 'white', pointerEvents: 'none'}}>Blogs</Link>
						            					}
						            					{(current_pathname == '/blogs') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_selected_color, textAlign:'center'}} to="/blogs" activeStyle={{color: 'white', pointerEvents: 'none'}}>Blogs</Link>
						            					}
									        
								            	</Menu.Item>

								            {/*	<Menu.Item style={{backgroundColor:'white', paddingRight:'2em'}} position= {'right'}  activeStyle={{backgroundColor: 'white', pointerEvents: 'none'}}>
									            	
									            		{(current_pathname !== '/books') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_not_selected_color, textAlign:'center'}} to="/books" activeStyle={{color: 'white', pointerEvents: 'none'}}>eBooks</Link>
						            					}
						            					{(current_pathname == '/books') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_selected_color, textAlign:'center'}} to="/books" activeStyle={{color: 'white', pointerEvents: 'none'}}>eBooks</Link>
						            					}
									        
								            	</Menu.Item>*/}

								            	<Menu.Item style={{backgroundColor:'white', paddingRight:'2em'}} position= {'right'}  activeStyle={{backgroundColor: 'white', pointerEvents: 'none'}}>
									            	
									            		{(current_pathname !== '/courses') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_not_selected_color, textAlign:'center'}} to="/courses" activeStyle={{color: 'white', pointerEvents: 'none'}}>Courses</Link>
						            					}
						            					{(current_pathname == '/courses') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_selected_color, textAlign:'center'}} to="/courses" activeStyle={{color: 'white', pointerEvents: 'none'}}>Courses</Link>
						            					}
									        
								            	</Menu.Item>

								            	{/*<Menu.Item style={{backgroundColor:'white', paddingRight:'2em'}} position= {'right'}  activeStyle={{backgroundColor: 'white', pointerEvents: 'none'}}>
									            	
									            		{(current_pathname !== '/newsletter') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_not_selected_color, textAlign:'center'}} to="/newsletter" activeStyle={{color: 'white', pointerEvents: 'none'}}>Newsletter</Link>
						            					}
						            					{(current_pathname == '/newsletter') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_selected_color, textAlign:'center'}} to="/newsletter" activeStyle={{color: 'white', pointerEvents: 'none'}}>Newsletter</Link>
						            					}
									        
								            	</Menu.Item>*/}


								            	


								            	<Menu.Item style={{backgroundColor:'white', paddingRight:'4em'}} position= {'right'}  activeStyle={{backgroundColor: 'white', pointerEvents: 'none'}}>
									            	
									            		{(current_pathname !== '/contact') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_not_selected_color, textAlign:'center'}} to="/contact" activeStyle={{color: 'white', pointerEvents: 'none'}}>Contact</Link>
						            					}
						            					{(current_pathname == '/contact') &&
						            					<Link style={{fontWeight: 'bold', fontSize: '1.5em', color:menu_selected_color, textAlign:'center'}} to="/contact" activeStyle={{color: 'white', pointerEvents: 'none'}}>Contact</Link>
						            					}
									        
								            	</Menu.Item>


								    


								            	
								            	
								            	{/*<Menu.Item style={{backgroundColor:'white', }}>
						
								            		
								            		<Dropdown style={{backgroundColor:'white', fontWeight: 'bold', fontSize: '1.5em', color:'teal'}} item text='Blog'>
											          <Dropdown.Menu style={{backgroundColor:'white'}}>
											            <Dropdown.Item><Link style={{fontSize: '1em', color:'teal'}} to="/blog_categories" activeStyle={{color: 'white', pointerEvents: 'none'}}>Categories</Link></Dropdown.Item>
											            <Dropdown.Item ><Link style={{fontSize: '1em', color:'teal'}} to="/blogs" activeStyle={{color: 'white', pointerEvents: 'none'}}>Blogs</Link></Dropdown.Item>
											          </Dropdown.Menu>
											        </Dropdown>
										
								            
								            	</Menu.Item>*/}
								            	
								            

				            				</Menu>
				            			
				            			

							            	</Grid.Row>
						            	</Grid>
				        

			            </Grid.Column>
				 

			        
		            
		            </Grid>
	       	
		     </Container>

		)
	}

}