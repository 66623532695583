import React from 'react';
import * as d3 from 'd3';
// import {scaleLinear} from 'd3-scale';
// import {max} from 'd3-array';
import {select} from 'd3-selection';


export default class BarChart extends React.Component {
   constructor(props){
      super(props)
      this.createBarChart = this.createBarChart.bind(this)
   }
   componentDidMount() {
      this.createBarChart()
   }
   componentDidUpdate() {
      d3.selectAll("g").remove();
      this.createBarChart()
   }

   componentWillUnmount(){
      let node =this.node;
      select(node).remove();
      d3.selectAll("svg").remove();
      d3.selectAll("text").remove();
      // if re-render after connecting to api real data will have to unmount. create ref to element in state first
   }

   createBarChart() {
      const node = this.node;

      var svg = select(node),
      margin = {top: 50, right: 50, bottom: 50, left: 50},
      width = this.props.width - margin.left - margin.right,
      height = this.props.height - margin.top - margin.bottom;

      const x_margin_adj = 200;

      var x = d3.scaleBand().rangeRound([0, width]).padding(0.1),
      y = d3.scaleLinear().rangeRound([height, 0]);

    //   var yScale = d3.scaleLinear()
    // .domain([0, 1]) // input 
    // .range([height, 0]); // output 

      var g = svg.append("g")
       // .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // d3.tsv(["data.tsv"], function(d) {
      // d.frequency = +d.frequency;
      // return d;
      // }, function(error, data) {
      // if (error) throw error;

      let num_heads = this.props.num_heads ? this.props.num_heads : 0;
      let num_tails = this.props.num_tails ? this.props.num_tails : 0;

      let data = [{'letter':'Heads', 'frequency': num_heads},{'letter':'Tails', 'frequency': num_tails}];



      x.domain(data.map(function(d) { return d.letter; }));
      y.domain([0, d3.max(data, function(d) { return d.frequency; })]);

      g.append("g")
         // .attr("class", "axis axis--x")
         .attr("transform", `translate(${width -x_margin_adj},` + ( height + margin['bottom']) + ")")
         // .attr("transform", translate(`${width}`, `${height}` )

         .call(d3.axisBottom(x));


         // svg.append("g")
         //     .attr("class", "y axis")
         //     .attr("dx", "5 em")
         //     .call(d3.axisLeft(y)) // Create an axis component with d3.axisLeft

      const yAxis = d3.axisLeft()
        .tickFormat(function(e){
            if(Math.floor(e) != e){
              return;
            }
            return e;
        })
        .scale(y)


        svg.append('g')
          .attr('class', 'axis axis--y')

          .attr('transform', `translate(${(width - x_margin_adj)},${margin['bottom']})`)
          .call(yAxis)

    // svg.append("text")
    //   .attr("transform", "rotate(-90)")
    //   .attr("y", (width + width))
    //   .attr("x",0 - (height / 2))
    //   .attr("dx", "1em")
    //   .attr("dy", "1em")
    //   .style("text-anchor", "middle")
    //   .style("stroke","white")
    //   .text("Frequency"); 

      // g.append("g")
      //  .append("text")
      //    .attr("transform", "rotate(0)")
      //    .attr("dy", "-1.5em")
      //    .attr("dx", "0em")
      //    .attr("text-anchor", "end")
      //    .text("Happy Hour")
      //    .style("font-size","1em")
      //    .attr("color","white");

       svg.append("g")
       .append("text")
         .attr("transform", "rotate(0)")
         .attr("dy", "1em")
         .attr("dx", `${(width- (x_margin_adj)/2)}`)
         // .attr("text-anchor", "end")
         .text("Total Coin Flips")
         .style("font-size","1em")
         .attr("color","white");

      g.selectAll(".bar")
       .data(data)
       .enter().append("rect")
         .attr("x", function(d) { return d.letter === 'Heads' ? (width) : (width) + 5} )
         .attr("y", height + margin['bottom'] )
         .transition()
         .duration(1000)
         .attr("x", function(d) { return x(d.letter) + (width -x_margin_adj); })
         .attr("y", function(d) { return y(d.frequency) + margin['bottom']; })
         .attr("fill", function(d){
            return d.letter === 'Heads'? 'orange' : 'purple';
         })
         .attr("width", x.bandwidth())
         .attr("height", function(d) { return height - y(d.frequency); });
          d3.selectAll("text").style("fill","white");
   
   }

	render(){
		return (
			<svg ref={node => this.node = node} style={{minWidth: 600, minHeight: 600, backgroundColor:'black'}}>
      	>
      		</svg>
		)
	}

}