import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

export default class AboutMe extends React.Component {

	constructor(props){
		super(props);

		this.state = {
         config_setting: 0,
      	}
		this.handleNavigationClick = this.handleNavigationClick.bind(this);
		this.handleClick = this.handleClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'github': 'https://github.com/jamesramadan',
			'medium': 'https://medium.com/@jamesramadan',
			'twitter': 'https://twitter.com/JamesWRamadan',
			// 'facebook': 'https://www.facebook.com/james.ramadan',
			'linkedin': 'https://www.linkedin.com/in/james-ramadan/',
			'playperformprofit': 'https://www.playperformprofit.com'
		};
		window.open(mapToNativigation[platform]);	
	}

	handleClick(){
      let { config_setting } = this.state;
      let random = Math.random();

      config_setting = config_setting + (random * 100);

      this.setState({config_setting: config_setting});


   }


	render(){
			// const mePic = '/Images/me.png';

		let {config_setting} = this.state;

		const surfsidePic = '/Images/me/surfside_pic.jpg';


		// const fontSizeMapper = word => Math.log2(word.value) * 4;
		const fontSizeMapper = word => Math.log2(word.value) * 7;
		const rotate = word => (word.value + config_setting) % 70;
		// const fill = (d, i) => scaleOrdinal(schemeCategory10)(i);

			return (
				<Container>
					<Grid stackable>
						<Grid.Row>
							<HeaderBar />
						</Grid.Row>


						<Grid.Row columns={2} centered  width={'36em'}>


						

							<Grid.Column centered>
							<Transition transitionOnMount={true} animation='scale' duration={500}>
		                        <Container style={{backgroundColor: '#FCF1DB'}}>
		                           <Card raised centered style ={{ backgroundColor: 'white', width: '32em',minHeight: '42.5em', border: '.2em solid #cdebf9'}}>
		
		                              <Card.Header style={{cursor: 'pointer', textAlign: 'center', color: '#a3a3a3', fontSize: '1.25em'}}>
		                                 <span>
		                                   James Ramadan
		                                 </span>
		                              </Card.Header>
		                              <Card.Meta style={{cursor: 'pointer', textAlign: 'center', color: 'gray', fontSize: '1em'}}>
		                                 <span>
		                                   Bio
		                                 </span>
		                              </Card.Meta>
		                              <Card.Content style={{cursor: 'pointer', color: 'black', fontSize: '1.25em'}}>
		                              <p>
		                              A former consultant & software developer turned product manager, James is a problem solver that enjoys building software products user love.
		                              </p>
		                              
		                              <p>
		                              He leads with empathy to understand user challenges, then plans and executes with extreme focus until those challenges are resolved.
		                              </p>

		                            
		                              <p>
		                              Analytically-minded, James appreciates setting and hitting goals, KPIs, and OKRs. He takes pride in building products on-time and within budget.
		                              </p>


		                              <p>
		                              A team-player, James loves being part of great teams, building meaningful relationships, and collaborating with others to achieve a common goal.
		                              </p>

		                              <p>
		                              Using his intuition, he can read people & anticipate markets. He understands what makes each individual special and helps push others to reach their potential.
		                              </p>

		                              <p>
		                              When he's not working on projects, he likes to stay physically active and keep learning new things. If he is relaxing, it is usually with some hummus.
		                              </p>

		                              </Card.Content>
		                           </Card>
		                  
		                        </Container>
	                        </Transition>
							</Grid.Column>

							<Grid.Column centered >
							<Transition transitionOnMount={true} animation='scale' duration={500}>
		                        <Container style={{backgroundColor: '#FCF1DB'}}>
		                           <Card raised centered style ={{ backgroundColor: 'white', border: '.05em solid gray', width: '32em',}}>
		                              <Image centered  size={'large'} src={surfsidePic} />
		   
		                           </Card>
		                  
		                        </Container>
	                        </Transition>
							</Grid.Column>
					

							
							



						</Grid.Row>

						<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'10em'}}>
		                <Container style={{backgroundColor:'#FCF1DB'}}/>
		               </Grid.Row >

					
				
					</Grid>
		
				</Container>
			)
		}

}



