import React from 'react';
import HeaderBar from './../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed, Input, Select, Button, Message, Checkbox} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";

export default class Courses extends React.Component {

	constructor(props){
		super(props);

		 this.state={
         waitlistEmail: '',
         validEmailSubmission: false,
         validEmailSubmissionCount: 0,
         invalidEmailWarning: false,
         notEnoughSelectionWarning: false,
         selectionOptions:{
	         productManagementCheckbox: false,
	         dataScienceCheckbox: false,
	         analyticsCheckbox: false,
	         technologyCheckbox: false,
	         blockchainCheckbox: false,
	         investingCheckbox: false,
	         personalGrowthCheckbox: false
	    }

      }

		this.markCheckbox = this.markCheckbox.bind(this);
		this.updateEmailValue = this.updateEmailValue.bind(this);
		this.addWaitlistEmail = this.addWaitlistEmail.bind(this);

	}


	markCheckbox(event, {value, checked}){

		// console.log('platform', platform)
		// console.log(value, checked);
		let { selectionOptions } = this.state

		selectionOptions[value] = checked;
		this.setState({selectionOptions:selectionOptions})
		
		
	}


	updateEmailValue(event, {value}){

		// console.log('platform', platform)
		// console.log(value);
		this.setState({waitlistEmail:value, validEmailSubmission:false })
		
		
	}

	async addWaitlistEmail(event) {
	    
	    let { waitlistEmail, selectionOptions, validEmailSubmissionCount} = this.state;

	     const { productManagementCheckbox, dataScienceCheckbox,
	    analyticsCheckbox, technologyCheckbox, blockchainCheckbox, investingCheckbox,
	    personalGrowthCheckbox } = selectionOptions;


	    if(productManagementCheckbox || dataScienceCheckbox || analyticsCheckbox || technologyCheckbox || blockchainCheckbox || investingCheckbox || personalGrowthCheckbox ){

		    if(waitlistEmail.length < 50 && waitlistEmail.includes("@") &&  waitlistEmail.includes(".")){

		    	
		    	// console.log(waitlistEmail);
		    	const response = await fetch(`/api/course_waitlist_request`, {
			        method: 'POST',
			        headers: {'Content-Type': 'application/json'},
			        body: JSON.stringify({data: {selectionOptions: selectionOptions, email: waitlistEmail}})
			      })
			    const success_response = await response.json();

			    // this.updateEmailValue(null, {'value': ''});
			    validEmailSubmissionCount += 1
			    this.setState({validEmailSubmission: true, invalidEmailWarning:false, notEnoughSelectionWarning: false, validEmailSubmissionCount: validEmailSubmissionCount});

		    } else {

		    	this.setState({validEmailSubmission: false, invalidEmailWarning: true, notEnoughSelectionWarning: false, validEmailSubmissionCount: validEmailSubmissionCount})

		    }

		} else {

		    	this.setState({validEmailSubmission: false, invalidEmailWarning: false, notEnoughSelectionWarning: true, validEmailSubmissionCount: validEmailSubmissionCount})

		}


	    
	}


	render(){
		const jamesPic = '/Images/me/james_looking_out.png';
		const mePic = '/Images/me/me.png';
		let { waitlistEmail, invalidEmailWarning, notEnoughSelectionWarning, validEmailSubmission, validEmailSubmissionCount} = this.state;

		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>		

					<Grid.Row columns={3} stretched>
		                     <Grid.Column verticalAlign={'middle'} >

		                     </Grid.Column>
		                     <Grid.Column verticalAlign={'middle'} textAlign={'center'}>          
		                        <Header as='h4' style={{color: 'black', fontSize:'1.75em'}}> New Online Courses & Training </Header>
		                     </Grid.Column> 
		                     <Grid.Column >
		                     </Grid.Column>
		                </Grid.Row>



					<Grid.Row stackable columns={2} centered width={'36em'}>

					  <Grid.Column centered stretched>

					  	<Transition transitionOnMount={true} animation='scale' duration={500}>
			            	<Segment textAlign={'left'} style={{backgroundColor: 'white'}}>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			New online courses and training are in-progress, including in the following areas:
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.25em', color: 'gray'}} > 
			            			- Product Management & Development <Checkbox value='productManagementCheckbox' onChange={(event, data)=>{this.markCheckbox(event, data)}} />
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.25em', color: 'gray'}} > 
			            			- Data Science, AI, & Machine Learning <Checkbox value='dataScienceCheckbox' onChange={(event, data)=>{this.markCheckbox(event, data)}} />
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.25em', color: 'gray'}} > 
			            			- Analytics & Business Intelligence <Checkbox value='analyticsCheckbox' onChange={(event, data)=>{this.markCheckbox(event, data)}} />
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.25em', color: 'gray'}} > 
			            			- Technology & Software Development <Checkbox value='technologyCheckbox' onChange={(event, data)=>{this.markCheckbox(event, data)}} />
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.25em', color: 'gray'}} > 
			            			- Blockchain & Cryptocurrencies <Checkbox value='blockchainCheckbox' onChange={(event, data)=>{this.markCheckbox(event, data)}} />
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.25em', color: 'gray'}} > 
			            			- Strategic Investing & Finance <Checkbox value='investingCheckbox' onChange={(event, data)=>{this.markCheckbox(event, data)}} />
			            		</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.25em', color: 'gray'}} > 
			            			- Personal Growth & Productivity <Checkbox value='personalGrowthCheckbox' onChange={(event, data)=>{this.markCheckbox(event, data)}} />
			            		</Header>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			Mark your area(s) of interest, and provide your email below to hear more about upcoming courses and training opportunities!
			            		</Header>

			            	</Segment>

		            	</Transition>
		            

		            	</Grid.Column>

					</Grid.Row>


					{ ((validEmailSubmissionCount % 2) == 1) &&
					<Grid.Row stackable columns={2} centered width={'36em'}>

			            		<Container centered fluid style={{width:'30em'}}>
			            		<Input centered fluid type='text' placeholder='Email' action onChange={(event, data)=>{this.updateEmailValue(event, data);}} >
							
									<input  />
								    <Button type='submit' color="teal" onClick={(event)=>{this.addWaitlistEmail(event);}}>Submit</Button>
								  </Input>

								</Container>
					</Grid.Row>
					}
					{ (validEmailSubmissionCount == 0 || ((validEmailSubmissionCount % 2) == 0)) &&
					<Grid.Row stackable columns={2} centered width={'36em'}>

			            		<Container centered fluid style={{width:'30em'}}>
			            		<Input centered fluid type='text' placeholder='Email' action onChange={(event, data)=>{this.updateEmailValue(event, data);}} >
							
									<input  />
								    <Button type='submit' color="teal" onClick={(event)=>{this.addWaitlistEmail(event);}}>Submit</Button>
								  </Input>

								</Container>
					</Grid.Row>
					}


					{ (notEnoughSelectionWarning) &&
					<Grid.Row stackable columns={2} centered width={'36em'}>

			            		<Container centered fluid style={{width:'30em'}}>
			            		<Message warning>
								    <Message.Header>Select at least 1 category</Message.Header>
								    <p>Please select at least 1 category before submitting your email</p>
								  </Message>
								</Container>
								
					</Grid.Row>
					}

					{ (invalidEmailWarning) &&
					<Grid.Row stackable columns={2} centered width={'36em'}>

			            		<Container centered fluid style={{width:'30em'}}>
			            		<Message negative>
								    <Message.Header>Invalid email</Message.Header>
								    <p>Please enter in a valid email address to hear about more opportunities</p>
								  </Message>
								</Container>
								
					</Grid.Row>
					}

					{ (validEmailSubmission) &&
					<Grid.Row stackable columns={2} centered width={'36em'}>

			            		<Container centered fluid style={{width:'30em'}}>
			            		<Message positive>
								    <Message.Header>{waitlistEmail} submitted</Message.Header>
								    <p> Thanks for your interest! You will be notified of upcoming courses based on your interest! </p>
								  </Message>
								</Container>
								
					</Grid.Row>
					}
					

					
					{ ( notEnoughSelectionWarning || invalidEmailWarning || validEmailSubmission) &&
					<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'10em'}}>
                		<Container style={{backgroundColor:'#FCF1DB'}}/>
               		</Grid.Row >
               		}

               		{ (!notEnoughSelectionWarning &&!invalidEmailWarning && !validEmailSubmission) &&
					<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'17em'}}>
                		<Container style={{backgroundColor:'#FCF1DB'}}/>
               		</Grid.Row >
               		}

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>