import React from 'react';
import HeaderBar from './../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed, Input, Select, Button, List} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";

export default class Newsletter extends React.Component {

	constructor(props){
		super(props);
		this.handleNavigationClick = this.handleNavigationClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'medium-1': 'https://medium.com/swlh/want-to-keep-your-restaurant-employees-you-must-do-these-3-things-to-succeed-641ed9e672be',
			'medium-2': 'https://medium.com/swlh/the-top-5-ways-to-optimize-the-talent-pipeline-in-your-organization-62d89db69419',
			'medical1': 'https://www.ncbi.nlm.nih.gov/pubmed/26018251',
			'medical2': 'https://www.ncbi.nlm.nih.gov/pubmed/23836031',
			'medical3': 'https://www.ncbi.nlm.nih.gov/pubmed/21944825',
		};
		window.open(mapToNativigation[platform]);	
	}


	render(){
		const jamesPic = '/Images/me/james_looking_out.png';
		const mePic = '/Images/me/me.png';

		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>		

					<Grid.Row columns={3} stretched>
		                     <Grid.Column verticalAlign={'middle'} >

		                     </Grid.Column>
		                     <Grid.Column verticalAlign={'middle'} textAlign={'center'}>          
		                        <Header as='h4' style={{color: 'black', fontSize:'1.75em'}}> Newsletter</Header>
		                     </Grid.Column> 
		                     <Grid.Column >
		                     </Grid.Column>
		                </Grid.Row>




					<Grid.Row stackable columns={2} centered width={'36em'}>

					  <Grid.Column centered stretched>

					  	<Transition transitionOnMount={true} animation='scale' duration={500}>
			            	<Segment textAlign={'left'} style={{backgroundColor: 'white'}}>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			I run a free newsletter where I share my thoughts on various subjects.
			            		</Header>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 

			            			Topics include:
			            		</Header>
			            		<List bulleted>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Product Management
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Technology
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Data Science
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Business
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Personal Growth
			            		</List.Item>
			            		<List.Item  style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} >
			            			Investing
			            		</List.Item>
			            		</List>

			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			Sign up below to receive newletter content in your inbox!
			            		</Header>

			            		<Container centered fluid style={{width:'25em'}}>
			            		<Input centered fluid type='text' placeholder='Email' action>
							
									<input />
								    <Button type='submit' color="teal">Submit</Button>
								  </Input>

								</Container>
			            	</Segment>

		            	</Transition>
		            

		            	</Grid.Column>

					</Grid.Row>

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>