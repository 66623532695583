import React from 'react';
import * as d3 from 'd3';
// import {scaleLinear} from 'd3-scale';
// import {max} from 'd3-array';
import {select} from 'd3-selection';


export default class StackedBarChart extends React.Component {
   constructor(props){
      super(props)
      this.createBarChart = this.createBarChart.bind(this)
   }
   componentDidMount() {
      this.createBarChart()
   }
   // componentDidUpdate() {
   //    this.createBarChart()
   // }

   componentWillUnmount(){
      let node =this.node;
      select(node).remove();
      d3.selectAll("svg").remove();
      //select(this.state.element).remove if re-render after connecting to api real data will have to unmount. create ref to element in state first
   }

   createBarChart() {
      const node = this.node;
      const ref = this.ref;



     const causes = ['wounds', 'other', 'disease']
      const parseDate = d3.timeParse('%m/%Y')
      const margin = { top: 20, right: 100, bottom: 60, left: 60 }
      const width = 500
      const height = 300

      const x = d3.scaleBand()
        .rangeRound([0, width])

      const y = d3.scaleLinear()
        .rangeRound([height, 0])

      const z = d3.scaleOrdinal(d3.schemeCategory10)

      const xAxis = d3.axisBottom()
        .scale(x)
        .tickFormat(d3.timeFormat('%b'))

      const yAxis = d3.axisRight()
        .scale(y)

      const svg = d3.select('body')
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const type = (d) => {
        d.date = parseDate(d.date)
        causes.forEach(c => {
          d[c] = +d[c]
        })
        return d
      }

      let crimea = [{date:'1/1854', 'disease': 30, 'wounds': 31, 'other': 9},
                    {date:'2/1854', 'disease': 20, 'wounds': 30, 'other': 20},
                    {date:'3/1854', 'disease': 20, 'wounds': 40, 'other': 10},
                    {date:'4/1854', 'disease': 15, 'wounds': 25, 'other': 30},
                    {date:'5/1854', 'disease': 10, 'wounds': 20, 'other': 40}
                    ];

      // date  total disease wounds  other
      // 4/1854  8571  1 0 5
      // 5/1854  23333 12  0 9
      // 6/1854  28333 11  0 6
      // 7/1854  28772 359 0 23
      // 8/1854  30246 828 1 30
      // 9/1854  30290 788 81  70
      // 10/1854 30643 503 132 128
      // 11/1854 29736 844 287 106
      // 12/1854 32779 1725  114 131
      // 1/1855  32393 2761  83  324
      // 2/1855  30919 2120  42  361
      // 3/1855  30107 1205  32  172
      // 4/1855  32252 477 48  57
      // 5/1855  35473 508 49  37
      // 6/1855  38863 802 209 31
      // 7/1855  42647 382 134 33
      // 8/1855  44614 483 164 25
      // 9/1855  47751 189 276 20
      // 10/1855 46852 128 53  18
      // 11/1855 37853 178 33  32
      // 12/1855 43217 91  18  28
      // 1/1856  44212 42  2 48
      // 2/1856  43485 24  0 19
      // 3/1856  46140 15  0 35

      // d3.tsv('./crimea.tsv', type, (crimea) => {

        // console.log('cr', crimea)

        const layers = d3.stack()
        .keys(causes)
        (crimea)

        x.domain(layers[0].map(d => d.data.date))

        y.domain([0, d3.max(layers[layers.length - 1], d => (d[0] + d[1]) )]).nice()

        const layer = svg.selectAll('layer')
          .data(layers)
          .enter()
          .append('g')
          .attr('class', 'layer')
          .style('fill', (d, i) => (z(i) ))

        layer.selectAll('rect')
          .data(d => d)
          .enter()
          .append('rect')
          .attr('x', d => x(d.data.date))
          .attr('y', d => y(d[0] + d[1]))
          .attr('height', d => y(d[0]) - y(d[1] + d[0]))
          .attr('width', x.bandwidth() - 1)

        svg.append('g')
          .attr('class', 'axis axis--x')
          .attr('transform', `translate(0,${height})`)
          .style("stroke","white")
          .call(xAxis)

        svg.append("text")
          .attr("y", height)
          .attr("x", width /2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("stroke","white")
          .text("Softball Team Color"); 

        svg.append('g')
          .attr('class', 'axis axis--y')
          .attr('transform', `translate(${width},0)`)
          .style("stroke","white")
          .call(yAxis)

        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", width + 50)
          .attr("x",0 - (height / 2))
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("stroke","white")
          .text("Team Wins By Season"); 
      // })
   
   }

	render(){
		return (
			<svg ref={node => this.node = node} width={0} height={0}
      	>
      		</svg>
		)
	}

}