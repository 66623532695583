import React, { Component } from 'react';
import {Redirect, Switch, MemoryRouter} from 'react-router';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import startsWith from 'lodash/startsWith';

import Landing from './Landing/Landing';
import Blogs from './Blogs/Blogs';
import Books from './Books/Books';
import Courses from './Courses/Courses';
import Newsletter from './Newsletter/Newsletter';
import AboutMe from './About/AboutMe/AboutMe';
import Contact from './Contact/Contact';


import CV from './About/CV/CV';
import Blog_Categories from './Blogs/Blog_Categories';

import Project_List from './About/Projects/Project_List';
import UnloadX from './About/Projects/UnloadX/UnloadX';
// import NLP_Language_Detection from './NLP_Language_Detection/NLP_Language_Detection';
import Coin_Flipper from './About/Projects/D3_Visuals/Coin_Flipper';
import Line_And_Stacked_Charts from './About/Projects/D3_Visuals/Line_And_Stacked_Charts';
import Collision_Detection from './About/Projects/D3_Visuals/Collision_Detection';
import D3_Social_Network from './About/Projects/D3_Visuals/Social_Network';
import Publications from './About/Projects/Publications/Publications';

import StripeComponents from "./Components/Stripe/StripeComponents";
import SuccessPurchaseComponent from "./Components/PurchasePages/SuccessPurchaseComponent";
import CancelPurchaseComponent from "./Components/PurchasePages/CancelPurchaseComponent";
import DownloadProduct from "./Components/PurchasePages/DownloadProduct";

import FooterBar from './Components/FooterBar/FooterBar';
import PrivacyPolicy from './Components/FooterBar/PrivacyPolicy';
import TermsAndConditions from './Components/FooterBar/TermsAndConditions';

//Color Pallete
//teal - 008080
// light gray - A3A3A3
//off white - FCF1DB
//tan - EB8969
//maroon -D35134
//baby blue -'#cdebf9'

//C8E4E2


import './App.css';

class App extends Component {

  constructor(){

    super();

    // this.state = {
    //      dark_mode: false
    // }
    // this.handleDarkModeClick = this.handleDarkModeClick.bind(this);
  }

  // handleDarkModeClick(){
  //     let { dark_mode } = this.state.dark_mode;
  //     let new_dark_mode = !dark_mode;
  //     this.setState({dark_mode: new_dark_mode});

  //     // this.setState((prevState) => ({ reload_wordcloud: !prevState.reload_wordcloud }))


  //  }

  render() {

    // let {  } = this.state;

    // const color_scheme = {'gray': '#a8a8a8', 'blue': '#008db9', blue_team: '#c3d9ff', 'orange': '#FF5E54', orange_team: '#ffc966', 'green': '#84BA65' };


  


    //## COLOR SCHEME RIGHT NOW
    //BLACK
    //WHITE
    //#ODBAE6 (teal blue) //discarded
    //TEAL
    //#4C5454 (dark gray)
    //#FF715B(light red)
    //#0000ff (blue)

    const Router = startsWith(window.location.href, "file:") ? MemoryRouter : BrowserRouter;

    return (
      <div className="App">
        <Router>
          
            {/*Auth*/}
       
    
            {/*Routes*/}
            
            <Routes>
            
              <Route path="/landing" element= {<Landing /> } />
              <Route path="/about_me" element= {<AboutMe /> } />
              <Route path="/cv" element= {<CV /> } />
              <Route path="/blog_categories" element= {<Blog_Categories /> } />
              <Route path="/blogs" element= {<Blogs /> } />
              <Route path="/books" element= {<Books /> } />
              <Route path="/checkout_form" element= {<StripeComponents /> } />
              <Route path="/purchase_success" element= {<SuccessPurchaseComponent /> } />
              <Route path="/purchase_cancel" element= {<CancelPurchaseComponent /> } />
              <Route path="/download_product/:transaction_id" element= {<DownloadProduct /> } />
              <Route path="/courses" element= {<Courses /> } />
              <Route path="/newsletter" element= {<Newsletter /> } />
              <Route path="/contact" element= {<Contact /> } />
              {/*
              <Route path="/nlp_language_detection" element= {props => <NLP_Language_Detection /> } />
            */}
              <Route path="/project_list" element= {<Project_List /> } />
              <Route path="/unloadX" element= {<UnloadX /> } />
              <Route path="/coin_flipper" element= {<Coin_Flipper /> } />
              <Route path="/line_and_stacked_charts" element= {<Line_And_Stacked_Charts /> } />
              <Route path="/collision_detection_chart" element= {<Collision_Detection /> } />
              <Route path="/d3social_network" element= {<D3_Social_Network /> } />
              <Route path="/publications" element= {<Publications /> } />

              <Route path="/privacy_policy" element= {<PrivacyPolicy /> } />
              <Route path="/terms_and_conditions" element= {<TermsAndConditions /> } />

              <Route exact path="/*" element= {<Landing />  }/>
              
              

            </Routes>
            
          
           
            <FooterBar />
           
          
        </Router>
      </div>
    );
  }
}

export default App;
