import React from 'react';
import HeaderBar from './../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup} from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

export default class Landing extends React.Component {

	constructor(props){
		super(props);

		this.state = {
         config_setting: 0,
         reload_wordcloud: true,
      	}
		this.handleNavigationClick = this.handleNavigationClick.bind(this);
		this.handleClick = this.handleClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'github': 'https://github.com/jamesramadan',
			'medium': 'https://medium.com/@jamesramadan',
			'twitter': 'https://twitter.com/JamesWRamadan',
			// 'facebook': 'https://www.facebook.com/james.ramadan',
			'linkedin': 'https://www.linkedin.com/in/james-ramadan/',
			'playperformprofit': 'https://www.playperformprofit.com'
		};
		window.open(mapToNativigation[platform]);	
	}

	handleClick(){
      let { config_setting } = this.state;
      let random = Math.random();

      config_setting = config_setting + (random * 100);

      this.setState({config_setting: config_setting});

      this.setState((prevState) => ({ reload_wordcloud: !prevState.reload_wordcloud }))


   }


	render(){
			// const mePic = '/Images/me.png';

		let {config_setting, reload_wordcloud} = this.state;

		// let {dark_mode} = this.props;
		// let text_color = 'white';
		// let background_color = 'white';
		// let dark_mode_text = 'Dark Mode';
		// (dark_mode == true) ? (text_color ='white') : (text_color ='white');
		// (dark_mode == true) ? (background_color ='white') : (background_color ='white');
		// (dark_mode == true) ? (dark_mode_text ='Bring The Light') : (dark_mode_text ='Go Dark');

		const mePic = '/Images/me/self_head_shot.png';

		const data_upper = [
		  { text: 'Creative', value: 120  },
		  { text: 'Driven', value: 360 },
		  { text: 'Logical', value: 20 },
		  { text: 'Intuitive', value: 30 },
		  { text: 'Empathetic', value: 120 },
		  { text: 'User-Focused', value: 10 },
		  { text: 'Goal-Oriented', value: 60 },
		];

		const data_lower = [
		  { text: 'Adaptable', value: 360 },
		  { text: 'Precise', value: 20 },
		  { text: 'Follow-through', value: 30  },
		  { text: 'Persistent', value: 120 },
		  { text: 'Patient', value: 10 },
		  { text: 'Authentic', value: 50 },
		];

		// const fontSizeMapper = word => Math.log2(word.value) * 4;
		const fontSizeMapper = word => Math.log2(word.value) * 7;
		const rotate = word => (word.value + config_setting) % 70;
		// const fill = (d, i) => scaleOrdinal(schemeCategory10)(i);

			return (
				<Container >
					<Grid stackable >
						<Grid.Row>
							<HeaderBar />
						</Grid.Row>


						<Grid.Row columns={2} centered  width={'36em'}>


							{(reload_wordcloud) &&
							<Grid.Column centered >
								<Grid>
									<Grid.Row>
									<Transition transitionOnMount={true} animation='flash' duration={500}>
						            	<Container textAlign={'center'} style={{backgroundColor: 'white',  border: '.1em solid #0dbae6'}}>
						            		

							                     <WordCloud
							                     	width= {500}
							                     	height={100}
												    data={data_upper}
												    fontSizeMapper={fontSizeMapper}
												    rotate={rotate}
												  />
							              
						            		<Header style={{fontSize: '4em', color: 'black'}} > James Ramadan </Header>
						            		<Header style={{fontSize: '2em', color: 'black'}} > Welcome to my site! </Header>
						            		<Header style={{fontSize: '1em', color: 'black'}} > <span style={{fontSize: '1em', color: 'teal', cursor: 'pointer'}}  onClick={this.handleClick} > Click </span> around to explore! </Header>
						            		
						            	
						            		
							                     <WordCloud
							                     	width= {500}
							                     	height={200}
												    data={data_lower}
												    fontSizeMapper={fontSizeMapper}
												    rotate={rotate}
												  />
							            
							          
							          
						            	</Container>
					            	</Transition>
					            	</Grid.Row>
					            	<Grid.Row>
									
						            	{/*<Container textAlign={'center'} style={{backgroundColor: 'white', marginTop: '5em', border: '.1em solid #0dbae6'}}>
						            		
							                     <Card fluid>
							                    	 <Card.Content style={{color:'black'}} fluid> Here for my Zen Product Management eBook? Click <Link style={{fontWeight: 'bold', fontSize: '1em', color:'teal', textAlign:'center'}} to="/books" activeStyle={{color: 'white', pointerEvents: 'none'}}>here</Link>. </Card.Content>
							                     </Card>
							          
						            	</Container>*/}
					            	
					            	</Grid.Row>
				            	</Grid>
			            	</Grid.Column>
			            	}

			            	{(!reload_wordcloud) &&
			            	<Grid.Column centered >
							<Grid>
									<Grid.Row>
									<Transition transitionOnMount={true} animation='flash' duration={500}>
						            	<Container textAlign={'center'} style={{backgroundColor: 'white',  border: '.1em solid #0dbae6'}}>
						            		

							                     <WordCloud
							                     	width= {500}
							                     	height={100}
												    data={data_upper}
												    fontSizeMapper={fontSizeMapper}
												    rotate={rotate}
												  />
							              
						            		<Header style={{fontSize: '4em', color: 'black'}} > James Ramadan </Header>
						            		<Header style={{fontSize: '2em', color: 'black'}} > Welcome to my site! </Header>
						            		<Header style={{fontSize: '1em', color: 'black'}} > <span style={{fontSize: '1em', color: 'teal', cursor: 'pointer'}}  onClick={this.handleClick} > Click </span> around to explore! </Header>
						            		
						            	
						            		
							                     <WordCloud
							                     	width= {500}
							                     	height={200}
												    data={data_lower}
												    fontSizeMapper={fontSizeMapper}
												    rotate={rotate}
												  />
							            
							          
							          
						            	</Container>
					            	</Transition>
					            	</Grid.Row>
					            	<Grid.Row>
									
						            	{/*<Container textAlign={'center'} style={{backgroundColor: 'white', marginTop: '5em', border: '.1em solid #0dbae6'}}>
						            		
							                     <Card fluid>
							                    	 <Card.Content style={{color:'black'}} fluid> Here for my Zen Product Management eBook? Click <Link style={{fontWeight: 'bold', fontSize: '1em', color:'teal', textAlign:'center'}} to="/books" activeStyle={{color: 'white', pointerEvents: 'none'}}>here</Link>.</Card.Content>
							                     </Card>
							          
						            	</Container>*/}
					            	
					            	</Grid.Row>
				            	</Grid>
			            	</Grid.Column>
			            	}
						
							{(reload_wordcloud) &&
							<Grid.Column centered >
							<Transition transitionOnMount={true} animation='shake' duration={1000}>
					
							<Card centered onClick={this.handleClick} style ={{ backgroundColor: '#a3a3a3', width: '40em'}}>
								<Image centered size='large' src={mePic} />
								
							</Card>

							</Transition>
							</Grid.Column>
							}

							{(!reload_wordcloud) &&
							<Grid.Column centered >
							<Transition transitionOnMount={true} animation='shake' duration={1000}>
					
							<Card centered onClick={this.handleClick} style ={{ backgroundColor: '#a3a3a3',  width: '40em'}}>
								<Image centered size='large' src={mePic} />
								
							</Card>

							</Transition>


							</Grid.Column>
							}
					

							
							



						</Grid.Row>

						<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'5em'}}>
		                <Container style={{backgroundColor:'#FCF1DB'}}/>
		               </Grid.Row >

					
				
					</Grid>
		
				</Container>
			)
		}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>