import React from 'react';
import HeaderBar from './../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";

export default class Blog_Categories extends React.Component {

	constructor(props){
		super(props);
		this.handleNavigationClick = this.handleNavigationClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'medium-1': 'https://medium.com/swlh/want-to-keep-your-restaurant-employees-you-must-do-these-3-things-to-succeed-641ed9e672be',
			'medium-2': 'https://medium.com/swlh/the-top-5-ways-to-optimize-the-talent-pipeline-in-your-organization-62d89db69419',
			'medical1': 'https://www.ncbi.nlm.nih.gov/pubmed/26018251',
			'medical2': 'https://www.ncbi.nlm.nih.gov/pubmed/23836031',
			'medical3': 'https://www.ncbi.nlm.nih.gov/pubmed/21944825',
		};
		window.open(mapToNativigation[platform]);	
	}


	render(){
		const jamesPic = '/Images/james_looking_out.png';
		const mePic = '/Images/me.png';

		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>

					<Grid.Row centered>					
						<Header as='h4' style={{color: '#a3a3a3', fontSize:'1.75em'}}> Blog Categories </Header>
					</Grid.Row>		


					<Grid.Row stackable columns={2} centered width={'36em'}>

					  <Card.Group itemsPerRow={6}>
					    <Card>
						    <Image src= {mePic} wrapped ui={false} />
						    <Card.Content>
						      <Card.Header>Matthew</Card.Header>
						      <Card.Meta>
						        <span className='date'>Joined in 2015</span>
						      </Card.Meta>
						      <Card.Description>
						        Matthew is a musician living in Nashville.
						      </Card.Description>
						    </Card.Content>
						    <Card.Content extra>
						      <a>
						        <Icon name='user' />
						        22 Friends
						      </a>
						    </Card.Content>
						</Card>
					    <Card color='orange' image={mePic} />
					    <Card color='yellow' image={mePic} />
					    <Card color='olive' image={mePic} />
					    <Card color='green' image={mePic} />
					    <Card color='teal' image={mePic} />
					    <Card color='blue' image={mePic} />
					    <Card color='violet' image={mePic} />
					    <Card color='purple' image={mePic} />
					    <Card color='pink' image={mePic} />
					    <Card color='brown' image={mePic} />
					    <Card color='grey' image={mePic} />
					 </Card.Group>
						



					</Grid.Row>

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>