import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed, Menu} from 'semantic-ui-react';
import {Link } from 'react-router-dom'
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Coin_Flipper from './D3_Visuals/Coin_Flipper';
import D3_Collision from './D3_Visuals/Collision_Detection';

import UnloadX from './UnloadX/UnloadX';

export default class Project_List extends React.Component {

	constructor(props){
		super(props);
		this.state = {activeItem:'d3 Collision'}
		this.handleNavigationClick = this.handleNavigationClick.bind(this);
		this.handleMenuItemClick = this.handleMenuItemClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'DeepLearning': 'https://github.com/jamesramadan/Deep-Learning-Example',
			'Divvy': 'https://github.com/devhart/divvy',
			'Crumbs': 'https://github.com/devhart/crumbs-react-native',
		};
		window.open(mapToNativigation[platform]);	
	}

	handleMenuItemClick(e, { name }){

		// console.log('platform', platform)
		
		 this.setState({ activeItem: name })
	}


	render(){
		const jamesImprov = '/Images/me/james_improv.png';
		const unloadXTeam = '/Images/me/unloadXTeam.png';

		const mePic = '/Images/me/me_hiking.png';
		const jamesPic = '/Images/me/james_looking_out.png';

		const { activeItem } = this.state


		return (
			<Container>
				<Grid fluid rows={4} stackable >
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>

					<Grid.Row centered>					
						<Header as='h4' style={{color: 'black', fontSize:'1.75em'}}> Project Demos</Header>
					</Grid.Row>


					<Grid.Row fluid centered >
					<Segment fluid>
				        <Menu fliud color={'black'} style={{backgroundColor: 'white'}}   pointing secondary >
				          
				      	  
				      	  { (activeItem == 'd3 Collision') &&
				          <Menu.Item
				            name='d3 Collision'
				           
				            color={'black'}
				            active={activeItem === 'd3 Collision'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }
				      	  { (activeItem !== 'd3 Collision') &&
				          <Menu.Item
				            name='d3 Collision'
				           
				            color={'teal'}
				            active={activeItem !== 'd3 Collision'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }
				      	  { (activeItem == 'Coin Flipper') &&
				          <Menu.Item
				            name='Coin Flipper'
				           
				            color={'black'}
				            active={activeItem === 'Coin Flipper'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }
				      	  { (activeItem !== 'Coin Flipper') &&
				          <Menu.Item
				            name='Coin Flipper'
				           
				            color={'teal'}
				            active={activeItem !== 'Coin Flipper'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }

				      	  { (activeItem == 'unloadX') &&
				          <Menu.Item
				            name='unloadX'
				           
				            color={'black'}
				            active={activeItem === 'unloadX'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }
				      	  { (activeItem !== 'unloadX') &&
				          <Menu.Item
				            name='unloadX'
				           
				            color={'teal'}
				            active={activeItem !== 'unloadX'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }
				
				      	  { (activeItem == 'Github') &&
				          <Menu.Item
				            name='Github'
				           
				            color={'black'}
				            active={activeItem === 'Github'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }
				      	  { (activeItem !== 'Github') &&
				          <Menu.Item
				            name='Github'
				           
				            color={'teal'}
				            active={activeItem !== 'Github'}
				            onClick={this.handleMenuItemClick}
				          />
				      	  }
				        </Menu>
				     </Segment>
				     </Grid.Row>


					<Grid.Row fluid centered>

						<Grid fluid stackable>

							

							<Grid.Column fluid centered style={{backgroundColor: '#FCF1DB'}}>

							<Container fluid style={{backgroundColor: '#FCF1DB'}}>
						
								{/*d3 Collision*/}
								{(activeItem == 'd3 Collision') &&
								<Grid.Row fluid stackable columns={2} centered  >
			                      
			                        <Transition transitionOnMount={true} animation='scale' duration={500}>
			                   
			                         	<D3_Collision/>
			              
			                        </Transition>

			                   </Grid.Row>
			              		 }


			              		{/*coin flipper*/}
								{(activeItem == 'Coin Flipper') &&
								<Grid.Row fluid stackable columns={2} centered  >
			                      
			                        <Transition transitionOnMount={true} animation='scale' duration={500}>
			                   
			                         	<Coin_Flipper/>
			              
			                        </Transition>

			                   </Grid.Row>
			              		 }
						

								{/*UnloadX*/}
								{(activeItem == 'unloadX') &&
								<Grid.Row fluid stackable columns={2} centered  >
			                      
			                        <Transition transitionOnMount={true} animation='scale' duration={500}>
			                   
			                         	<UnloadX/>
			              
			                        </Transition>

			                   </Grid.Row>
			              		 }



								{(activeItem == 'Github') &&
								<Grid.Row centered style={{padding: '1em'}}>
								<Transition transitionOnMount={true} animation='scale' duration={1300}>
					      
					            		
					            		<Header as='h3' style={{textAlign: 'left', fontSize: '1.5em', color: '#a3a3a3'}} >
					            			<div style={{display: 'inline-block'}} >
					            			 	<span onClick={()=>{this.handleNavigationClick('DeepLearning');}} style={{cursor: 'pointer', color: 'teal'}}>Language Prediction Deep Learning </span>
					            			</div>
					            		</Header>       		
					            	
					    
				            	</Transition>
				            	</Grid.Row>
				            	}

				            	{(activeItem == 'Github') &&
				            	<Grid.Row centered style={{padding: '1em'}}>
								<Transition transitionOnMount={true} animation='scale' duration={1500}>
					          
					            		
					            		<Header as='h3' style={{textAlign: 'left', fontSize: '1.5em', color: '#a3a3a3'}} >
					            			<div  style={{display: 'inline-block'}} >
					            			 	<span onClick={()=>{this.handleNavigationClick('Divvy');}} style={{cursor: 'pointer', color: 'teal'}}>Divvy - Cost Split Application </span>
					            			</div>
					            		</Header>       		
					            	
					         
				            	</Transition>
				            	</Grid.Row>
				            	}

				            	{(activeItem == 'Github') &&
				            	<Grid.Row centered style={{padding: '1em'}}>
								<Transition transitionOnMount={true} animation='scale' duration={1700}>
					
					            		
					            		<Header as='h3' style={{textAlign: 'left', fontSize: '1.5em', color: '#a3a3a3'}} >
					            			<div  style={{display: 'inline-block'}} >
					            			 	<span onClick={()=>{this.handleNavigationClick('Crumbs');}} style={{cursor: 'pointer', color: 'teal'}}>Crumbs - Geolocation Chat Application </span>
					            			</div>
					            		</Header>       		
					            	
					         
				            	</Transition>
				            	</Grid.Row>
				            	}

				           

			     

			            	</Container>
			            	</Grid.Column>

			            	
		            	</Grid>

		            	</Grid.Row>

		            	<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'10em'}}>
		                <Container style={{backgroundColor:'#FCF1DB'}}/>
		               </Grid.Row >


		            


		

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>