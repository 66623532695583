import React from 'react';
import moment from 'moment';

import { NavLink, useNavigate, Link } from 'react-router-dom'

import { Container, Segment, Grid, Header, Image, Transition, Menu, Dropdown, Card} from 'semantic-ui-react';

export default class FooterBar extends React.Component {
    constructor(props){
      super(props)

      // this.state={
      //    activeItem: 'home'

      // }

      // this.handleItemClick = this.handleItemClick.bind(this);

    }

    // handleItemClick = (activeItem) => {

    // 	console.log('here' , activeItem)

    // 	this.setState({ activeItem: activeItem })

    // }

 

	render(){

		const logo = '/Images/companylogos/ppp_banner.png';
		const ensoLogo = '/Images/white_enso.png';
		const yinYangLogo = '/Images/yin_yang.svg';

		let current_date = moment().format('MMMM DD, YYYY');

		let current_pathname = window.location.pathname;
		if(current_pathname == '/'){
			current_pathname = '/landing';
		}

		const onLandingAlready = (current_pathname == '/landing') ? true : false;

		const menu_not_selected_color = 'teal';
		const menu_selected_color = 'black';
	

		return (
			<Container fluid className="App-header" style={{backgroundColor: '#a3a3a3',  marginTop: '1em', minHeight: '3em'}}>
				
					{/*<div style={{display: 'inline-block', float:'left', color:'#ffc96c', paddingTop: '2vh', marginLeft: '2vh'}} className="dateHeaderFont">
		        		<span> {current_date} </span>
		            </div>*/}
		            <Grid centered columns={6  } >
			         	<Grid.Column  centered  >
			         	</Grid.Column>

		
			            <Grid.Column  centered  >
				            	
				            		<Container fluid wrapped style={{fontWeight: 'bold', fontSize: '0.75em', textAlign:'center', color:'black', backgroundColor: '#a3a3a3'}}>
				            			<Link style={{fontSize: '1em', color:'white'}} to="/privacy_policy" activeStyle={{color: 'white', pointerEvents: 'none'}}>Privacy Policy</Link>
				            		</Container>
				            		<Container fluid wrapped style={{fontWeight: 'bold', fontSize: '0.75em', textAlign:'center', color:'black', backgroundColor: '#a3a3a3'}}>
				            			<Link style={{fontSize: '1em', color:'white'}} to="/terms_and_conditions" activeStyle={{color: 'white', pointerEvents: 'none'}}>Terms & Conditions</Link>
				            		</Container>
	
			            </Grid.Column>

			            <Grid.Column  centered  >
			         	</Grid.Column>
			         	<Grid.Column  centered  >
			         	</Grid.Column>

			         	<Grid.Column  centered  >
			         
				            		<Container fluid style={{fontWeight: 'bold', fontSize: '0.75em', textAlign:'center', color:'white', backgroundColor: '#a3a3a3', border: '0em'}}>
				            		
				            		
				            			James Ramadan is a product manager that puts his end users first. He values solving end user problems using technology, and has experience in the education, finance, & healthcare sectors.
				            
				            		</Container>
				            	
			            </Grid.Column>

			            <Grid.Column  centered  >
			         	</Grid.Column>

				 

			        
		            
		            </Grid>
	       	
		     </Container>

		)
	}

}