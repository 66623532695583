import React from 'react';
import ReactDOM from 'react-dom'
import * as d3 from "d3";
// import {scaleLinear} from 'd3-scale';
// import {max} from 'd3-array';
import {select} from 'd3-selection';




export default class DonutChart extends React.Component {
   constructor(props){
      super(props)
      this.createPieChart = this.createPieChart.bind(this)
   }
   componentDidMount() {
      this.createPieChart()
   }
   componentDidUpdate() {
      this.createPieChart()
   }

   componentWillUnmount(){
    let node =this.node;
      select(node).remove();
   d3.selectAll("svg").remove();
      //select(this.state.element).remove if re-render after connecting to api real data will have to unmount. create ref to element in state first
   }

   createPieChart() {

   // let elem = ReactDOM.findDOMNode(this.node);
   //  let elem = this.node;
   // console.log(elem)
   // ReactDOM.findDOMNode(component)
  // console.log(this.props);

   let margin = {top: 0, right: 0, bottom: 0, left: 0};

   let duration = 1500,
    // transition = 200,
    percent = !!this.props.percent ? this.props.percent : 0,

    dollar_flag = this.props.dollar_flag ? this.props.dollar_flag : undefined,
    value = this.props.value ? this.props.value : undefined,
    // team_color = this.props.team_color ? this.props.team_color : undefined,
    // team_color = this.props.team_color ? this.props.team_color : undefined,
    team_color = 'white',
    width = this.props.width- margin.left - margin.right,
    height = this.props.height - margin.top - margin.bottom;
    // width = this.props.width,
    // height = this.props.height;


         let dataset = {
                     lower: calcPercent(0),
                     upper: calcPercent(percent)
                 },
                 radius = Math.min(width, height) / 2,
                 pie = d3.pie().sort(null),
                 format = d3.format(".0%"),
                 format2 = d3.format("($,d"),
                 format3 = d3.format("(,d");

         let arc = d3.arc()
                 .innerRadius(radius * .6)
                 .outerRadius(radius* .65);

         const node = this.node;

         let svg = select(node)
                 .attr("width", width )
                 .attr("height", height )
                 .append("g")
                 .attr("transform", "translate(" + width /2 + "," + height / 2 + ")");


         let path = svg.selectAll("path")
                         .data(pie(dataset.lower))
                         .enter().append("path")
                         // .attr("class", function (d, i) {
                         //     return "color" + i;
                         // })
                         .attr("d", arc)
                         .attr('fill', function(d, i) {
                            //i = 0 represents filled class. select color.
                            let color = "grey";
                            //#B03060 -- red, #006600 --green. #FFD700 --yellow
                            if(i===0){
                              if(percent < 33){
                                return "white"; //red
                              } else if(percent < 66){
                                return "white"; //yellow
                              } else if(percent <= 100){
                                return (value ? team_color :"white"); //green returned if percent. team_color returned if non-percent value is present.
                              }
                            } 

                            // if(i===0){
                            //   if(percent < 33){
                            //     return "#B03060"; //red
                            //   } else if(percent < 66){
                            //     return "#FFD700"; //yellow
                            //   } else if(percent <= 100){
                            //     return (value ? team_color :"#006600"); //green returned if percent. team_color returned if non-percent value is present.
                            //   }
                            // } 
                            return color;
                          })
                         .each(function (d) {
                             this._current = d;
                         })
                         // .attr("filter", "url(#dropshadow)");



          // var filter = svg.append("filter")
          //             .attr("id", "dropshadow")

          //         filter.append("feGaussianBlur")
          //             .attr("in", "SourceAlpha")
          //             .attr("stdDeviation", 6)
          //             .attr("result", "blur");
          //         filter.append("feOffset")
          //             .attr("in", "blur")
          //             .attr("dx", 10)
          //             .attr("dy", 20)
          //             .attr("result", "offsetBlur")

          //         var feMerge = filter.append("feMerge");

          //         feMerge.append("feMergeNode")
          //             .attr("in", "offsetBlur")
          //         feMerge.append("feMergeNode")
          //             .attr("in", "SourceGraphic");

         let text = svg.append("text")
                 .attr("text-anchor", "middle")
                 .attr("dy", ".3em")
                 // .attr("font-color","white");
                 // .attr("font-size",".1em")






        // const xShift = {Sales: '1.2em', Tip: '.8em'}
                

        svg.append("g")
       .append("text")
         .attr("transform", "rotate(0)")
         .attr("dy", "-5em")
         .attr("dx", "4.5em")
         .attr("text-anchor", "end")
         .text(this.props.title)
         .style("font-size","1em")
         .attr("color","white");

         let progress = 0;
         let timeout;

         if(!value){

           timeout = setTimeout(function () {
               clearTimeout(timeout);
               path = path.data(pie(dataset.upper));
               path.transition().duration(duration).attrTween("d", function (a) {
                   let i = d3.interpolate(this._current, a);
                   let i2 = d3.interpolate(progress, percent)
                   this._current = i(0);
                   return function (t) {
                     text.text(format(i2(t) / 100));
                       return arc(i(t));
                   };
               });
           }, 200);

         } else {

           timeout = setTimeout(function () {
               clearTimeout(timeout);
               path = path.data(pie(dataset.upper));
               path.transition().duration(duration).attrTween("d", function (a) {
                   let i = d3.interpolate(this._current, a);
                   let i2 = d3.interpolate(progress, value)
                   this._current = i(0);
                  if(dollar_flag){
                     return function (t) {
                         text.text(format2(i2(t)));
                         return arc(i(t));
                     };
                  } else {
                    return function (t) {
                         text.text(format3(i2(t)));
                         return arc(i(t));
                     };
                  }
               });
           }, 200);

          
         }





         function calcPercent(percent) {
             return [percent, 100 - percent];
         };

         d3.selectAll("text").style("fill","white");
         // d3.selectAll("text").style("font-size","2.5em");


   }





	render(){
		return (
			<svg ref={node => this.node = node}
         >
      </svg>
		)
	}

}