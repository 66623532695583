import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Form, Input, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed, List, Button, TransitionablePortal, Pagination, Message} from 'semantic-ui-react';
import { Redirect, Link, useParams, useSearchParams } from 'react-router-dom';


import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";

import FileSaver from 'file-saver';




export default class DownloadProduct extends React.Component {

  constructor(props){
    super(props);


    this.state={
         
       product: '',
       email: '',
       access_code: '',
       invalidAccessCodeWarning: false,
       showloadingWheel: false

      }

    this.updateAccessCode = this.updateAccessCode.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);

  }


  updateAccessCode(event, {value}){
    this.setState({access_code:value})
  }


  async onDownloadClick(code){


      let { access_code } = this.state;
      // let { params } = this.props;

      console.log(this.props)

      let path = window.location.pathname
      let transaction_id = path.split('/')[2]

      console.log(transaction_id)

      this.setState({showloadingWheel:true})



      if(!!access_code){


        let data = {access_code: access_code};

        fetch("/api/make_product_download_request/" + transaction_id, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          responseType: 'arraybuffer',
          body: JSON.stringify(data)
        })
        .then(res => { return res.blob()})
        .then(async (blob) => {

          if(blob.size > 200){
  
            const url = window.URL.createObjectURL(blob,{type: "application/pdf"})
            var link = document.createElement('a');
            link.href = url;
            // console.log('l', link)
            link.setAttribute('download', 'ZPM.pdf');
            document.body.appendChild(link);
            await link.click();


            this.setState({invalidAccessCodeWarning:false, showloadingWheel:false})

          } else {
            this.setState({invalidAccessCodeWarning:true, showloadingWheel:false})
          }

        })
      } else {
        this.setState({invalidAccessCodeWarning:true, showloadingWheel:false})
      }

  }



  render(){

    let { invalidAccessCodeWarning, showloadingWheel } = this.state;


    return (
      <Container>
        <Grid rows={3} stackable>
          <Grid.Row>
            <HeaderBar/>
          </Grid.Row>




          <Grid.Row stackable columns={2} centered width={'36em'}>

            <Grid.Column centered stretched>

              <Transition transitionOnMount={true} animation='slide right' duration={500}>
                    <Container textAlign={'left'} style={{backgroundColor: 'white', padding:'1em'}}>

                    
                      <Header style={{fontSize: '2em', color: 'blue'}} > eBook Download Page </Header>
                      <Header style={{fontSize: '1.75em', color: 'gray'}} > Enter in your eBook access code and then download your eBook! </Header>

                      <Form >
       
                        
                        <Form.Field
                          id='form-input-control-access-code'
                          control={Input}
                          label='Access code'
                          maxLength={20}
                          placeholder='Access code'
                          onChange={(event, data)=>{this.updateAccessCode(event, data)}}
                        />

                        <Form.Field
                          id='form-button-control-public'
                          control={Button}
                          content='Download'
                          color='teal'
                          onClick={(event, data)=>{this.onDownloadClick(event, data)}}
                          style={{marginTop:'2em'}}
                        />

                      </Form>

                      { (showloadingWheel) &&
                      <Segment>
                        <Dimmer active inverted >
                          <Loader size='medium' indeterminate>Downloading</Loader>
                        </Dimmer>
                      </Segment>

                      }

                      { (invalidAccessCodeWarning) &&
              

                      <Container centered fluid style={{ marginTop: '1em'}}>
                          <Message negative>
                            <Message.Header>Invalid Download</Message.Header>
                            <p>Unable to download</p>
                          </Message>
                      </Container>
                              
                 
                      }
        

      

                    </Container>

                  </Transition>
                

                  </Grid.Column>

            



          </Grid.Row>

          <Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'34em'}}>
                    <Container style={{backgroundColor:'#FCF1DB'}}/>
                  </Grid.Row >
          

        
      
        </Grid>
  
      </Container>
    )
  }

}



// <Container>
//        <Header/>
//        <div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
//          <Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
//            <div style={{display: 'inline-block', verticalAlign: 'middle'}}>
                  
//                    <Image src={logo} size='large' />
//                    <Image src={logo2} size='large' />
//                  </div>
          
//          </Card>
//        </div>
//      </Container>