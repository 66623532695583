import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

export default class TermsAndConditions extends React.Component {

	constructor(props){
		super(props);

		

	}


	render(){


			return (
				<Container >
					<Grid stackable >
						<Grid.Row>
							<HeaderBar />
						</Grid.Row>


						<Grid.Row columns={2} centered  width={'36em'}>
							<Header style={{fontSize: '4em', color: 'black'}} > Terms And Conditions </Header>


							
							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} > 
							These terms and conditions outline the rules and regulations for the use of our website.

							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >

							By accessing this website we assume you accept these terms and conditions in full. Do not continue to use the website if you do not accept all of the terms and conditions stated on this page.
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >

							The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of . Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
							</Container>


							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							License
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								Unless otherwise stated, we own the intellectual property rights for all material on or produced by the website. All intellectual property rights are reserved. You may view and/or print pages from our website for your own personal use subject to restrictions set in these terms and conditions.
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								You must not:
							</Container>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								Republish material from https://www.jamesramadan.com
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								Sell, rent or sub-license material from https://www.jamesramadan.com
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								Reproduce, reverse engineer, duplicate or copy material from https://www.jamesramadan.com
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								Redistribute content from the website (unless content is specifically made for redistribution).
 							</Container>

 							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Reservation of Rights
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our website. You agree to immediately remove all links to our Web site upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our website, you agree to be bound to and abide by these linking terms and conditions.
 							</Container>

 							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Content Removal from our Website
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								If you find any link or content on our website or application objectionable for any reason, you may contact us. We will consider requests to remove links or content but will have no obligation to do so or to respond directly to you. While we want to ensure that the information is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.
 							</Container>

 							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Content Liability
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify and defend us against all claims arising out of or based upon your website. No link(s) may appear on any page on your website or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
 							</Container>

							<Header style={{fontSize: '1.25em', color: 'black', textAlign:'left', padding:'.2em'}} >
							Disclaimer
							</Header>

							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								limit or exclude our or your liability for death or personal injury resulting from negligence;
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								limit or exclude our or your liability for fraud or fraudulent misrepresentation;
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								limit any of our or your liabilities in any way that is not permitted under applicable law; or
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								exclude any of our or your liabilities that may not be excluded under applicable law.
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty. To the extent that the website or application and the information and services on the website or application are provided free of charge, we will not be liable for any loss or damage of any nature.
 							</Container>

 							<Container style={{fontSize: '1em', color: 'black', textAlign:'left', padding:'.2em'}} >
								jamesramadan.com may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
 							</Container>

 							

 			

						</Grid.Row>

						<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'5em'}}>
		                	<Container style={{backgroundColor:'#FCF1DB'}}/>
		               </Grid.Row >

					
				
					</Grid>
		
				</Container>
			)
		}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>