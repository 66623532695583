import React from 'react';
import HeaderBar from './../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed, List, Button, TransitionablePortal, Pagination} from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';

import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";


export default class Success_Purchase_Component extends React.Component {

	constructor(props){
		super(props);

	

	

	}



	render(){


		return (
			<Container>
				<Grid rows={3} stackable>
					<Grid.Row>
						<HeaderBar/>
					</Grid.Row>




					<Grid.Row stackable columns={2} centered width={'36em'}>

					  <Grid.Column centered stretched>

					  	<Transition transitionOnMount={true} animation='slide right' duration={500}>
			            	<Container textAlign={'left'} style={{backgroundColor: 'white', padding:'1em'}}>

			            	
			            		<Header style={{fontSize: '2em', color: 'green'}} > Success </Header>
			            		<Header style={{fontSize: '1.75em', color: 'black'}} > Thanks for your order!</Header>
			            		<Header as='h4' style={{textAlign: 'left', fontSize: '1.5em', color: 'gray'}} > 
			            			Please check your email for instructions on how to access your new eBook. Note that the email may take a few moments to arrive.
			            		</Header>
			            		

			  

			

			            	</Container>

		            	</Transition>
		            

		            	</Grid.Column>

						



					</Grid.Row>

					<Grid.Row  style={{backgroundColor:'#FCF1DB', minHeight:'34em'}}>
                		<Container style={{backgroundColor:'#FCF1DB'}}/>
               		</Grid.Row >
					

				
			
				</Grid>
	
			</Container>
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>