import React from 'react';
import HeaderBar from './../../../Components/HeaderBar/HeaderBar';
import { Container, Card, Icon, Image, Table, Header, Progress, Divider, Grid} from 'semantic-ui-react';
import DonutChart from './Components/DonutChart';
import BarChart from './Components/BarChart';
import StackedBarChart from './Components/StackedBarChart';
import LineChart from './Components/LineChart';
import BubbleChart from './Components/BubbleChart';

export default class LineAndStackedBarChart extends React.Component {

   constructor(props){
      super(props)
   }


   render(){

      return (
         <Container>
            <Grid rows={3} stackable>
               <Grid.Row>
                  <HeaderBar/>
               </Grid.Row>

               <Grid.Row columns={3} stretched>
                  <Grid.Column >

                  </Grid.Column>
                  <Grid.Column >          
                     <Header as='h4' style={{color: '#a3a3a3', fontSize:'1.75em'}}> D3 Charts </Header>
                  </Grid.Column> 
                  <Grid.Column >
                  </Grid.Column>
               </Grid.Row>

                <Grid.Row columns={3} stretched>
                  <Grid.Column >
                     <LineChart />
                  </Grid.Column>
                
                  <Grid.Column >
                     <StackedBarChart />
                  </Grid.Column>
               </Grid.Row>


          
   
               
                 
              
     
              

            </Grid>
   
         </Container>
      )
   }

}