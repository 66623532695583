import React from 'react';
import HeaderBar from './../../../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';


export default class UnloadX extends React.Component {

   constructor(props){
      super(props)
   }


   render(){
      const unloadXTeam = '/Images/me/unloadXTeam.png';

      // let width =  window.innerWidth;
      // let height = window.innerHeight;

      return (
         <Grid fluid stackable rows={3} centered  >
                                           
            <Grid.Row fluid stackable centered  style={{height:'50em'}}>
            <Card raised centered fluid style ={{ backgroundColor: 'white'}}> 
               <ReactPlayer width={'100%'} height={'100%'} centered url='https://www.youtube.com/watch?v=yKBVy27iGbI' />
            </Card>
            </Grid.Row >

        

            <Grid.Row fluid stackable centered  >
            <Card raised centered fluid  style ={{ backgroundColor: 'white'}}> 

               <Grid fluid stackable columns={2} centered   >

                  <Grid.Column fluid  >

                  
                  <Image centered  size={'large'} src={unloadXTeam} />

                 </Grid.Column >
                 <Grid.Column fluid centered >
                  <Header as='h1' textAlign='center'  centered fluid style={{ paddingTop:'.5em'}}>
                     UnloadX Team
                  </Header>
                  <Header as='h2' textAlign='center'  style={{color: 'gray'}} centered fluid>
                    
                       D3-visualized load balancing service
             
                  </Header>
                  <Card.Content style={{color: 'gray', fontSize: '1.5em', padding:'1em'}}>
             
                  How much traffic can your servers handle? 
                  </Card.Content>

                  <Card.Content style={{color: 'gray', fontSize: '1.5em', padding:'1em'}}>
                  This load-balancing micro-service routes artificial client HTTP requests to your servers after checking server system health (i.e. CPU and memory).
                  Front-end displays server system metric performance via D3.
                  </Card.Content>

                  </Grid.Column >

             </Grid>

            </Card>

            </Grid.Row >

            <Grid.Row style={{backgroundColor:'#FCF1DB', minHeight:'10em'}}>
               <Container style={{backgroundColor:'#FCF1DB'}}/>
            </Grid.Row >
                                         
                          
                                       
                                
                              

                             
         </Grid>  
      )
   }

}