import React from 'react';
import * as d3 from 'd3';
// import {scaleLinear} from 'd3-scale';
// import {max} from 'd3-array';
import {select} from 'd3-selection';


export default class CollisionDetection extends React.Component {
   constructor(props){
      super(props)
      this.createCollisionChart = this.createCollisionChart.bind(this)
   }
   componentDidMount() {
      this.createCollisionChart()
   }
   componentDidUpdate() {
      this.createCollisionChart()
   }

   componentWillUnmount(){
      let ref =this.ref;
      select(ref).remove();
      d3.selectAll("svg").remove();
      // if re-render after connecting to api real data will have to unmount. create ref to element in state first
   }

   createCollisionChart() {
      const ref = this.ref;


      var svg = d3.select("svg")

     
      let gravity_strength = this.props.gravity_strength;
      
      // var width = svg_main.attr("width");
      // var height = svg_main.attr("height") ;

      var width = window.innerWidth
      var height = window.innerHeight

      var nodes = d3.range(250).map(function() { return {r: Math.random() * 12 + 4}; }),
          root = nodes[0];
      var color = d3.scaleOrdinal().range(d3.schemeCategory10)

      root.radius = 0;
      root.fixed = true;

      const forceX = d3.forceX(width/3).strength(gravity_strength)
      const forceY = d3.forceY(height/3).strength(gravity_strength)

      
      var force = d3.forceSimulation()
      .velocityDecay(0.1)
      .force("x", forceX)
      .force("y", forceY)
      .force("collide", d3.forceCollide().radius(function(d){
        if(d === root){
          return Math.random() * 50 + 100;
        }
        return d.r + 0.5;
      }).iterations(5))
      .nodes(nodes).on("tick", ticked);

      d3.select("body").append("svg")
      .attr("width", width)
      .attr("height", height);

      svg.selectAll("circle")
          .data(nodes.slice(1))
          .enter().append("circle")
          .attr("r", function(d) { return d.r; })
          .style("fill", function(d, i) { return color(i % 3); });

      function ticked(e) {
          svg.selectAll("circle")
              .attr("cx", function(d) { return d.x; })
              .attr("cy", function(d) { return d.y; });
      };

      svg.on("mousemove", function() {
          var p1 = d3.mouse(this);
          root.fx = p1[0];
          root.fy = p1[1];
          force.alphaTarget(0.3).restart();//reheat the simulation
          svg.style("cursor", "move");
      });

   
   }

  render(){
    return (
      <svg style={{border: '.1em solid black', backgroundColor: 'black'}} gravity_strength={this.props.gravity_strength} width={window.innerWidth} height={window.innerHeight} ref={ref => this.ref = ref}
        >
          </svg>
    )
  }

}