import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Loader, Dimmer} from 'semantic-ui-react';

import CheckoutForm from "./CheckoutForm";
// import './Stripe.css';
// import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.


export default class StripeComponents extends React.Component {
  

  constructor(props){
    super(props);

    this.state= {options:
                        {clientSecret: null, appearance:{ theme: 'stripe'}, stripePromise: null},
                        productName:null,
                        returnURL:null

  };
    // this.handleNavigationClick = this.handleNavigationClick.bind(this);
    // this.handleOpen = this.handleOpen.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.handlePaginationChange = this.handlePaginationChange.bind(this);

    // this.showCheckout = this.showCheckout.bind(this);

    // const [clientSecret, setClientSecret] = useState("");

  

  }


  // showCheckout() {
  //     this.setState({ showCheckout: true });
  // }

  componentWillMount(){
    

    let { productName } = this.props;

    let that = this;

    fetch("/api/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "eBook" }] }),
      })
      .then((res) => res.json())
      .then((data) => {

        // setClientSecret(data.clientSecret)
        // console.log(data.returnURL)

        that.setState({options: 
                              {clientSecret: data.clientSecret, appearance:{ theme: 'stripe'},
                               stripePromise:  loadStripe(data.stripePromise) 
                              },
                       productName: productName,
                       returnURL:data.returnURL
                      });

      });

  }



  render(){

    let {options, returnURL} = this.state;
    let {clientSecret, stripePromise} = options;
    // console.log('returnURL', returnURL)

    return (
      <div className="Stripe">
        {!!clientSecret && !!returnURL && (
          <Elements options={options} stripe={stripePromise}>
              <CheckoutForm returnURL={returnURL} />
          </Elements>
        )}
        {(!clientSecret || !returnURL) && (
          <Dimmer active inverted style={{backgroundColor: '#FCF1DB', paddingTop: '10em'}}>
            <Loader inverted size='large'/>
          </Dimmer>
        )}
      </div>
    )
  }
}