import React from 'react';
import HeaderBar from './../Components/HeaderBar/HeaderBar';
import { Container, Card, Segment, Dimmer, Loader, Grid, Image, Header, Transition, Icon, Popup, Feed} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { IoLogoGithub, IoLogoTwitter, IoLogoFacebook, IoLogoLinkedin } from "react-icons/io"; //https://react-icons.netlify.com/#/icons/go
import { FaMedium } from "react-icons/fa";
import { GoChevronRight, GoChevronLeft} from "react-icons/go";

export default class Blogs extends React.Component {

	constructor(props){
		super(props);
		this.handleNavigationClick = this.handleNavigationClick.bind(this);

	}

	handleNavigationClick(platform){

		// console.log('platform', platform)
		
		let mapToNativigation = {
			'medium-1': 'https://medium.com/swlh/want-to-keep-your-restaurant-employees-you-must-do-these-3-things-to-succeed-641ed9e672be',
			'medium-2': 'https://medium.com/swlh/the-top-5-ways-to-optimize-the-talent-pipeline-in-your-organization-62d89db69419',
			'medium-3': 'https://medium.com/swlh/deep-learning-as-explained-to-a-6th-grader-e67784e1a25',
			'medium-4': 'https://medium.com/swlh/the-top-5-skills-of-a-great-tech-product-manager-1ff0fbf06874',
			
		};
		window.open(mapToNativigation[platform]);	
	}


	render(){
		// const jamesPic = '/Images/me/james_looking_out.png';
		// const mePic = '/Images/me/me.png';
		const restaurant_employee_retentionPic = '/Images/Medium/restaurant_employee_retention.jpg';
		const talent_pipelinePic = '/Images/Medium/talent_pipeline.jpg';
		const deep_learning_6th_graderPic = '/Images/Medium/deep_learning_6th_grader.jpg';
		const tech_product_managerPic = '/Images/Medium/tech_product_manager.jpg';

		return (
	
				<Grid rows={2} stackable >
					<Grid.Row  >
						<HeaderBar style={{backgroundColor:'#FCF1DB'}}/>
					</Grid.Row>	




						<Grid.Row columns={3} stretched>
		                     <Grid.Column verticalAlign={'middle'} >

		                     </Grid.Column>
		                     <Grid.Column verticalAlign={'middle'} textAlign={'center'}>          
		                        <Header as='h4' style={{color: 'black', fontSize:'1.75em'}}> Blog Posts </Header>
		                     </Grid.Column> 
		                     <Grid.Column >
		                     </Grid.Column>
		                </Grid.Row>

					<Grid rows={2} stackable >
						<Transition transitionOnMount={true} animation='fade right' duration={700}>
						<Grid.Row  >
							<Grid columns={2} stackable>
								
								
								<Grid.Column>
									<Grid columns={2} stackable stretched>
										<Grid.Column>
											
										</Grid.Column>
										<Grid.Column>
											<Card raised fluid onClick={()=>{this.handleNavigationClick('medium-4');}} style={{display: 'inline-block', cursor: 'pointer', backgroundColor:'white'}} >
											    <Image fluid src={tech_product_managerPic} size='medium' style={{height: '25em', width:'25em'}}/>
											</Card>
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column>
								
							
									
									<Card left raised style={{minHeight: '25em'}} textAlign='center'>
								
									    <Card.Content>
									      <Feed>
									      	<Feed.Content>
									      		<Feed.Date>Written on</Feed.Date>
										        <Feed.Summary>
										          <b>January 8, 2020</b>
										        </Feed.Summary>
									      	</Feed.Content>
									      </Feed>

									      <Container onClick={()=>{this.handleNavigationClick('medium-4');}} style={{display: 'inline-block', fontSize: '1.5em', cursor: 'pointer', color:'black', padding:'.75em'}} >
									      	<Card.Header >The Top 5 Skills Of A Great Tech Product Manager</Card.Header>
									      </Container>
									      
									      <Card.Description>
									       A brief overview of the skills that will help you as a technology product manager.
									      </Card.Description>
									    <Container onClick={()=>{this.handleNavigationClick('medium-4');}} style={{display: 'inline-block', padding:'.75em'}} >
				            			 	<span style={{cursor: 'pointer', color: 'teal'}}>Read</span>
				            			</Container>
									    </Card.Content>
									</Card>	
									


							
								
								</Grid.Column>
								
							</Grid>
						</Grid.Row>	
						</Transition>


						<Transition transitionOnMount={true} animation='fade left' duration={700}>
						<Grid.Row  >
							<Grid columns={2} stackable >
								
								
								<Grid.Column>
									<Grid columns={2} stackable streteched>
										<Grid.Column>
											
										</Grid.Column>
										<Grid.Column>
											<Card raised fluid onClick={()=>{this.handleNavigationClick('medium-3');}} style={{display: 'inline-block', cursor: 'pointer', backgroundColor:'teal'}} >
											    <Image fluid src={deep_learning_6th_graderPic} size='medium' style={{height: '25em', width:'25em'}}/>
											</Card>
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column>
								
							
									<Card left raised style={{minHeight: '25em'}} textAlign='center'>
								
									    <Card.Content>
									      <Feed>
									      	<Feed.Content>
									      		<Feed.Date>Written on</Feed.Date>
										        <Feed.Summary>
										          <b>January 6, 2020</b>
										        </Feed.Summary>
									      	</Feed.Content>
									      </Feed>

									      <Container onClick={()=>{this.handleNavigationClick('medium-3');}} style={{display: 'inline-block', fontSize: '1.5em', cursor: 'pointer', color:'black', padding:'.75em'}} >
									      	<Card.Header >Deep-Learning As Explained To A 6th Grader</Card.Header>
									      </Container>
									      
									      <Card.Description>
									       Published in Analytics Vidha. Have you heard of deep learning? Well, this article can explain it...but only if you have passed 6th grade.
									      </Card.Description>
									    <Container onClick={()=>{this.handleNavigationClick('medium-3');}} style={{display: 'inline-block', padding:'.75em'}} >
				            			 	<span style={{cursor: 'pointer', color: 'teal'}}>Read</span>
				            			</Container>
									    </Card.Content>
									</Card>	


							
								
								</Grid.Column>
								
							</Grid>
						</Grid.Row>	
						</Transition>


						<Transition transitionOnMount={true} animation='fade right' duration={700}>
						<Grid.Row  >
							<Grid columns={2} stackable>
								
								
								<Grid.Column>
									<Grid columns={2} stackable stretched>
										<Grid.Column>
											
										</Grid.Column>
										<Grid.Column>
											<Card raised fluid onClick={()=>{this.handleNavigationClick('medium-2');}} style={{display: 'inline-block', cursor: 'pointer', backgroundColor:'white'}} >
											    <Image fluid src={talent_pipelinePic} size='medium' style={{height: '25em', width:'25em'}}/>
											</Card>
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column>
								
							
									<Card left raised style={{minHeight: '25em'}} textAlign='center'>
								
									    <Card.Content>
									      <Feed>
									      	<Feed.Content>
									      		<Feed.Date>Written on</Feed.Date>
										        <Feed.Summary>
										          <b>October 14, 2019</b>
										        </Feed.Summary>
									      	</Feed.Content>
									      </Feed>

									      <Container onClick={()=>{this.handleNavigationClick('medium-2');}} style={{display: 'inline-block', fontSize: '1.5em', cursor: 'pointer', color:'black', padding:'.75em'}} >
									      	<Card.Header >The Top 5 Ways To Optimize The Talent Pipeline In Your Organization</Card.Header>
									      </Container>
									      
									      <Card.Description>
									       Want the best talent in your organization? This is a must read.
									      </Card.Description>
									    <Container onClick={()=>{this.handleNavigationClick('medium-2');}} style={{display: 'inline-block', padding:'.75em'}} >
				            			 	<span style={{cursor: 'pointer', color: 'teal'}}>Read</span>
				            			</Container>
									    </Card.Content>
									</Card>	


							
								
								</Grid.Column>


								
							</Grid>
						</Grid.Row>	
						</Transition>

						<Transition transitionOnMount={true} animation='fade left' duration={700}>
						<Grid.Row  >
							<Grid columns={2} stackable>
								
								
								<Grid.Column>
									<Grid columns={2} stackable stretched>
										<Grid.Column>
											
										</Grid.Column>
										<Grid.Column>
											<Card raised fluid onClick={()=>{this.handleNavigationClick('medium-1');}} style={{display: 'inline-block', cursor: 'pointer', backgroundColor:'teal'}} >
											    <Image fluid src={restaurant_employee_retentionPic} size='medium' style={{height: '25em', width:'25em'}}/>
											</Card>
										</Grid.Column>
									</Grid>
								</Grid.Column>

								<Grid.Column>
								
							
									<Card left raised style={{minHeight: '25em'}} textAlign='center'>
								
									    <Card.Content>
									      <Feed>
									      	<Feed.Content>
									      		<Feed.Date>Written on</Feed.Date>
										        <Feed.Summary>
										          <b>September 19, 2019</b>
										        </Feed.Summary>
									      	</Feed.Content>
									      </Feed>

									      <Container onClick={()=>{this.handleNavigationClick('medium-1');}} style={{display: 'inline-block', fontSize: '1.5em', cursor: 'pointer', color:'black', padding:'.75em'}} >
									      	<Card.Header >Want To Keep Your Restaurant Employees? You Won’t Succeed Unless You Do These 3 Things</Card.Header>
									      </Container>
									      
									      <Card.Description>
									       Published in "The Start Up". This article is for restaurants on employee retention however the lessons here extend more broadly to other industries as well.
									      </Card.Description>
									    <Container onClick={()=>{this.handleNavigationClick('medium-1');}} style={{display: 'inline-block', padding:'.75em'}} >
				            			 	<span style={{cursor: 'pointer', color: 'teal'}}>Read</span>
				            			</Container>
									    </Card.Content>
									</Card>	


							
								
								</Grid.Column>
								
							</Grid>
						</Grid.Row>	
						</Transition>
					</Grid>



				
			
				</Grid>
	
	
		)
	}

}



// <Container>
// 				<Header/>
// 				<div style ={{margin: '3vh 2vw', border: '.1em solid #0dbae6', borderRadius: '4em', minHeight: '50vh'}}>
// 					<Card fluid style ={{border: '.1em solid grey', borderRadius: '4em', minHeight: '80vh'}}>
// 						<div style={{display: 'inline-block', verticalAlign: 'middle'}}>
		            	
// 		            		<Image src={logo} size='large' />
// 		            		<Image src={logo2} size='large' />
// 		           	 	</div>
					
// 					</Card>
// 				</div>
// 			</Container>